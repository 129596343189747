<template>
  <v-container fluid grid-list-md>
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-card-title>
        <span class="cmd-title">Echantillon</span>
        <v-btn
          outlined
          rounded
          class="previous-btn"
          @click="gotoPrevious()"
        >
          <v-icon color="blue" size="24" left>
            fas fa-arrow-circle-left
          </v-icon>
          <span>
            Revenir à la commande
          </span>
        </v-btn>
        <v-spacer />
        <v-icon
          v-if="commande.etat !== '1'"
          title="Commande transmise"
          color="error"
          size="58"
        >
          fas fa-lock
        </v-icon>
        <v-icon class="com-icon" color="accent" size="58">
          fas fa-vial
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="12" md="3" class="read-created">
            <span class="read-label">N° echantillon :</span>
            <span>{{ echantillon.numero }}</span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="3" class="read-created">
            <span class="read-label">N° commande :</span>
            <span>{{ commande.numero }}</span>
          </v-col>
          <v-col sm="0" md="5" />
        </v-row>
        <v-row>
          <v-col sm="12" md="3">
            <v-select
              v-if="edition"
              v-model="echantillon.famille"
              :items="familles"
              :item-text="item => item.text"
              :item-value="item => item.value"
              :rules="required"
              label="Produit"
              no-data-text="famille non disponibles"
            />
            <span v-else>
              <span class="read-label">Produit :</span>
              <span>{{ getLibelle(echantillon.famille, 'famille') }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="3">
            <v-text-field
              v-if="edition"
              v-model="echantillon.reference"
              label="Votre référence échantillon"
              maxlength="100"
            />
            <span v-else>
              <span class="read-label">Votre référence échantillon :</span>
              <span>{{ echantillon.reference }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="3">
            <app-date
              v-if="edition"
              v-model="echantillon.date_fabrication"
              :initial-date="echantillon.date_fabrication"
              label="Date de fabrication"
              @dateChange="editFabDate"
            />
            <span v-else class="read-created">
              <span class="read-label">Date de fabrication :</span>
              <span>{{ getShownDate(echantillon.date_fabrication) }}</span>
            </span>
          </v-col>
          <v-col sm="12" md="1">
            <v-text-field
              v-if="edition"
              v-model="dateFabTime"
              type="time"
              label="Heure"
            />
            <span v-else class="read-created">
              <span class="read-label"> à </span>
              <span>{{ dateFabTime }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="3">
            <v-select
              v-if="edition"
              v-model="echantillon.sous_famille"
              :items="sousFamilles"
              :item-text="item => item.text"
              :item-value="item => item.value"
              :rules="required"
              label="Sous-famille de produit"
              no-data-text="Donnée non disponible"
            />
            <span v-else>
              <span class="read-label">Sous-famille de produit :</span>
              <span>{{ sousFamille }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="3">
            <v-text-field
              v-if="edition"
              v-model="echantillon.identification"
              label="Votre identification"
              maxlength="110"
            />
            <span v-else>
              <span class="read-label">Votre identification :</span>
              <span>{{ echantillon.identification }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="3">
            <app-date
              v-if="edition"
              v-model="echantillon.date_prelevement"
              :initial-date="echantillon.date_prelevement"
              label="Date de prélèvement"
              @dateChange="editPrelDate"
            />
            <span v-else>
              <span class="read-label">Date de prélèvement :</span>
              <span>{{ getShownDate(echantillon.date_prelevement) }}</span>
            </span>
          </v-col>
          <v-col sm="12" md="1">
            <v-text-field
              v-if="edition"
              v-model="datePrevTime"
              type="time"
              label="Heure"
            />
            <span v-else class="read-created">
              <span class="read-label"> à </span>
              <span>{{ datePrevTime }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="2">
            <v-text-field
              v-if="edition"
              v-model="echantillon.quantite"
              type="number"
              label="Quantité"
              :rules="required"
            />
            <span v-else>
              <span class="read-label">Quantité :</span>
              <span v-if="echantillon.quantite !== '0'">{{ echantillon.quantite }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1">
            <v-select
              v-if="edition"
              v-model="echantillon.unite"
              :items="unites"
              :item-text="item => item.text"
              :item-value="item => item.value"
              :rules="required"
              label="Unité"
              no-data-text="Donnée non disponible"
            />
            <span v-else>
              <span class="read-label">Unité :</span>
              <span>{{ unite }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="3">
            <v-text-field
              v-if="edition"
              v-model="echantillon.lot"
              label="Votre référence lot"
              maxlength="50"
            />
            <span v-else>
              <span class="read-label">Votre référence lot :</span>
              <span>{{ echantillon.lot }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="4">
            <app-date
              v-if="edition"
              v-model="echantillon.date_dlc"
              :initial-date="echantillon.date_dlc"
              label="Date limite de consommation"
              @dateChange="editLCDate"
            />
            <span v-else>
              <span class="read-label">Date limite de consommation :</span>
              <span>{{ getShownDate(echantillon.date_dlc) }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="3">
            <v-autocomplete
              v-if="edition"
              v-model="echantillon.lmr"
              :items="lmrs"
              no-data-text="Limite Maximale Réglementaire - LMR non disponible"
              label="Limite Maximale Réglementaire - LMR"
            />
            <span v-else>
              <span class="read-label">Limite Maximale Réglementaire - LMR :</span>
              <span>{{ lmr }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="5" />
          <v-col sm="12" md="4">
            <app-date
              v-if="edition"
              v-model="echantillon.date_ddm"
              :initial-date="echantillon.date_ddm"
              label="Date de durabilité minimale"
              @dateChange="editDMDate"
            />
            <span v-else>
              <span class="read-label">Date de durabilité minimale :</span>
              <span>{{ getShownDate(echantillon.date_ddm) }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="3">
            <v-textarea
              v-if="edition"
              v-model="echantillon.info_1"
              auto-grow
              :label="infos.info1"
              rows="1"
              maxlength="40"
            />
            <span v-else>
              <span class="read-label">{{ infos.info1 }} :</span>
              <span>{{ echantillon.info_1 }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="3">
            <v-textarea
              v-if="edition"
              v-model="echantillon.info_2"
              auto-grow
              :label="infos.info2"
              rows="1"
              maxlength="40"
            />
            <span v-else>
              <span class="read-label">{{ infos.info2 }} :</span>
              <span>{{ echantillon.info_2 }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="1" />
          <v-col sm="12" md="4">
            <v-textarea
              v-if="edition"
              v-model="echantillon.info_3"
              auto-grow
              :label="infos.info3"
              rows="1"
              maxlength="40"
            />
            <span v-else>
              <span class="read-label">{{ infos.info3 }} :</span>
              <span>{{ echantillon.info_3 }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="3">
            <v-textarea
              v-if="edition"
              v-model="echantillon.info_4"
              auto-grow
              :label="infos.info4"
              rows="1"
              maxlength="40"
            />
            <span v-else>
              <span class="read-label">{{ infos.info4 }} :</span>
              <span>{{ echantillon.info_4 }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="9" />
        </v-row>
        <v-row>
          <v-col sm="12" md="12">
            <span class="ana-title">Analyses disponibles sur cet échantillon :</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="7">
            <v-autocomplete
              v-if="edition"
              v-model="echantillon.analyses"
              :items="analyses"
              dense
              small-chips
              deletable-chips
              no-data-text="Analyses non disponibles"
              label="Analyses"
              multiple
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ item.prix }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-autocomplete>
            <div v-else>
              <v-list-item
                v-for="(item, i) in analysesInfo"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title class="analyse-libelle">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ item.prix }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="7">
            <v-textarea
              v-if="edition"
              v-model="echantillon.commentaire"
              auto-grow
              label="Commentaire pour le laboratoire (non repris sur le rapport d'essai) :"
              messages="analyse non présente, demande particulière à destination du laboratoire..."
              rows="1"
              maxlength="1000"
            />
            <span v-else>
              <span class="read-label">Commentaire pour le laboratoire (non repris sur le rapport d'essai) :</span>
              <span>{{ echantillon.commentaire }}</span>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="!edition && commande.etat === '1'" color="info" @click="addOtherEchantillon()">
          <v-icon left>
            fas fa-plus
          </v-icon>
          Saisir un autre échantillon
        </v-btn>
        <v-spacer />

        <v-btn v-if="!edition && commande.etat === '1'" color="error" @click="askDeleteEchantillon()">
          <v-icon left>
            fas fa-trash-alt
          </v-icon>
          Supprimer
        </v-btn>

        <v-btn v-if="!edition && commande.etat === '1'" color="warning" @click="duplicateEchantillon()">
          <v-icon left>
            fas fa-clone
          </v-icon>
          Dupliquer
        </v-btn>

        <v-btn v-if="edition && !echantillon.numero && commande.etat === '1'" color="info" @click="saveEchantillon()">
          <v-icon left>
            fas fa-save
          </v-icon>
          Enregistrer
        </v-btn>

        <v-btn v-else-if="edition && echantillon.numero && commande.etat === '1'" color="info" @click="updateEchantillon()">
          <v-icon left>
            fas fa-save
          </v-icon>
          Enregistrer
        </v-btn>

        <v-btn v-else-if="commande.etat === '1'" color="info" @click="editEchantillon()">
          <v-icon left>
            fas fa-edit
          </v-icon>
          Modifier
        </v-btn>

        <v-btn v-if="!edition && commande.etat === '1' && commande.echantillons && commande.echantillons.length > 0" color="primary" @click="gotoPrevious()">
          <v-icon left>
            fas fa-lock
          </v-icon>
          Finaliser la commande
        </v-btn>
      </v-card-actions>
    </v-card>
    <app-dialog
      :active="isToDelete"
      :is-to-confirm="true"
      :message="message"
      @confirmDialog="confirmDialog"
    />
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import mixins from '@/mixins/mixins.js';
import AppDialog from '@/components/common/AppDialog';
import AppDate from "@/components/common/AppDate";
import searchSrv from "@/services/searchService";
import updateSrv from "@/services/updateService";
import moment from "moment";

export default {
  components: {
    AppDialog,
    AppDate
  },
  mixins: [mixins],
  data() {
    return {
      commande: {},
      familles: [],
      sousFamilles: [],
      unites: [],
      lmrs: [],
      analyses: [],
      analysesInfo: [],
      edition: true,
      famille: "",
      sousFamille: "",
      unite: "",
      lmr: "",
      dateFabTime: "00:00",
      datePrevTime: "00:00",
      infos: {},
      echantillon: {
        numero: null,
        commande_id: null,
        reference: "",
        lot: "",
        identification: "",
        famille: "",
        sous_famille: "",
        quantite: "",
        unite: "",
        commentaire: "",
        date_creation: this.getFormatedDate(new Date()),
        date_fabrication: null,
        date_prelevement: null,
        date_dlc: null,
        date_ddm: null,
        lmr: "",
        analyses: [],
        info_1: "",
        info_2: "",
        info_3: "",
        info_4: ""
      },
      message: "",
      isToDelete: false,
      required: [v => !!v || "Champ requis"]
    };
  },

  computed: {
    ...mapGetters("auth", ["isLogin", "user"]),
    ...mapGetters("data", ["currentCommande", "listAnalyses", "listFamille", "listUnites", "listInfos", "listLmrs"])
  },

  watch: {
    'echantillon.famille': function(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.getSousFamilles();
      }
    },
    'echantillon.quantite': function(newVal, oldVal) {
      if(newVal !== oldVal && newVal > 999999999999999999999999999999) {
        this.$toast.warning("La valeur de la quantité est trop grande");
      }
    },
    'echantillon.commentaire': function(newVal, oldVal) {
      if(newVal && newVal !== oldVal && newVal.length > 1000) {
        this.$toast.warning("Le commentaire est trop long");
      }
    },
    'echantillon.info1': function(newVal, oldVal) {
      if(newVal !== oldVal && newVal.length > 40) {
        this.$toast.warning("L'info 1 est trop longue");
      }
    },
    'echantillon.info2': function(newVal, oldVal) {
      if(newVal !== oldVal && newVal.length > 40) {
        this.$toast.warning("L'info 2 est trop longue");
      }
    },
    'echantillon.info3': function(newVal, oldVal) {
      if(newVal !== oldVal && newVal.length > 40) {
        this.$toast.warning("L'info 3 est trop longue");
      }
    },
    'echantillon.info4': function(newVal, oldVal) {
      if(newVal !== oldVal && newVal.length > 40) {
        this.$toast.warning("L'info 4 est trop longue");
      }
    },
  },

  created() {
    if (!this.echantillon.date_creation) {
      this.echantillon.date_creation = moment(new Date()).format("L");
    }
    if(this.$route.params.cid && this.$route.params.id) {
      this.edition = false;
    }
    if(this.currentCommande && this.currentCommande.id) {
      this.commande = this.currentCommande;
    }
    else {
      this.getCommande();
    }
    this.getEchantillon();
    this.getAnalyses();
    this.getFamilles();
    this.getUnites();
    this.getInfos();
    this.getLmrs();
  },

  mounted() {
    this.setLastEchVisited(
      {
        cid: this.$route.params.cid,
        id: this.$route.params.id
      }
    );
  },

  methods: {
    // Mapping des mutations du store.
    ...mapMutations("loading", ["LOADING"]),
    ...mapMutations("data", ["setLastEchVisited", "setListFamille", "setListUnites", "setListAnalyses", "setListInfos",  "setListLmrs", "setCurrentCommande"]),
    checkMandatoryFields() {
      if (!this.echantillon.famille) {
        this.$toast.warning("Le produit n'est pas renseigné");
        return false;
      }
      if (!this.echantillon.sous_famille) {
        this.$toast.warning("La sous famille de produit n'est pas renseignée");
        return false;
      }
      if (!this.echantillon.quantite) {
        this.$toast.warning("La quantité n'est pas renseignée");
        return false;
      }
      if (!this.echantillon.unite) {
        this.$toast.warning("L'unité n'est pas renseignée");
        return false;
      }
      if (!this.echantillon.identification) {
        this.$toast.warning("L'identification n'est pas renseignée");
        return false;
      }
      if(this.echantillon.date_dlc && this.echantillon.date_fabrication && this.isBeforeDate(this.echantillon.date_dlc, this.echantillon.date_fabrication)) {
        this.$toast.warning("La date de fabrication doit être avant la date de limite de consommation");
        return false;
      }
      if(this.echantillon.date_ddm && this.echantillon.date_fabrication && this.isBeforeDate(this.echantillon.date_ddm, this.echantillon.date_fabrication)) {
        this.$toast.warning("La date de fabrication doit être avant la date de durabilité minimale");
        return false;
      }
      if(this.echantillon.date_dlc && this.echantillon.date_prelevement && this.isBeforeDate(this.echantillon.date_dlc, this.echantillon.date_prelevement)) {
        this.$toast.warning("La date de prélèvement doit être avant la date de limite de consommation");
        return false;
      }
      if(this.echantillon.date_ddm && this.echantillon.date_prelevement && this.isBeforeDate(this.echantillon.date_ddm, this.echantillon.date_prelevement)) {
        this.$toast.warning("La date de prélèvement doit être avant la date de durabilité minimale");
        return false;
      }
      return true;
    },
    gotoPrevious() {
      if(this.$route.params.cid) {
        this.$router.push("/commande/" + this.$route.params.cid);
      }
    },
    goTo(page) {
      this.$router.push("/" + page + "/");
    },
    getFormatedTimeDate(date) {
      return moment(date).format("YYYY-MM-DD") + " 00:00:00";
    },
    getFormatedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getEditedDate(date) {
      if(date) {
        return moment(date).format("YYYY-MM-DD");
      }
      return null;
    },
    getEditedTime(date) {
      if(date) {
        return moment(date).format("HH:mm");
      }
      return "";
    },
    isBeforeDate(date1, date2) {
      return moment(date1).isBefore(date2);
    },
    editFabDate(date) {
      if(date.length <= 10) {
        this.echantillon.date_fabrication = this.getFormatedDate(date);
      }
    },
    editPrelDate(date) {
      if(date.length <= 10) {
        this.echantillon.date_prelevement = this.getFormatedDate(date);
      }
    },
    editLCDate(date) {
      if(this.echantillon.date_fabrication && this.isBeforeDate(date, this.echantillon.date_fabrication)) {
        this.$toast.warning("La date limite de consommation doit être aprés la date de fabrication");
        this.echantillon.date_dlc = this.getFormatedTimeDate(date);
        return;
      }
      if(this.echantillon.date_prelevement && this.isBeforeDate(date, this.echantillon.date_prelevement)) {
        this.$toast.warning("La date limite de consommation doit être aprés la date de prélèvement");
        this.echantillon.date_dlc = this.getFormatedTimeDate(date);
        return;
      }
      this.echantillon.date_dlc = this.getFormatedTimeDate(date);
    },
    editDMDate(date) {
      if(this.echantillon.date_fabrication && this.isBeforeDate(date, this.echantillon.date_fabrication)) {
        this.$toast.warning("La date de durabilité minimale doit être aprés la date de fabrication");
        this.echantillon.date_ddm = this.getFormatedTimeDate(date);
        return;
      }
      if(this.echantillon.date_prelevement && this.isBeforeDate(date, this.echantillon.date_prelevement)) {
        this.$toast.warning("La date de durabilité minimale doit être aprés la date de prélèvement");
        this.echantillon.date_ddm = this.getFormatedTimeDate(date);
        return;
      }
      this.echantillon.date_ddm = this.getFormatedTimeDate(date);
    },
    editEchantillon() {
      this.edition = true;
      this.echantillon.date_dlc = this.getEditedDate(this.echantillon.date_dlc);
      let dft = this.getEditedTime(this.echantillon.date_fabrication);
      let dpt = this.getEditedTime(this.echantillon.date_prelevement);
      this.dateFabTime = dft ? dft : "00:00";
      this.datePrevTime = dpt ? dpt : "00:00";
      this.echantillon.date_fabrication = this.getEditedDate(this.echantillon.date_fabrication);
      this.echantillon.date_prelevement = this.getEditedDate(this.echantillon.date_prelevement);
      this.echantillon.date_ddm = this.getEditedDate(this.echantillon.date_ddm);
    },
    getInfoAnalyses(echantillon) {
      let anl = [];
      if(echantillon && echantillon.analyses) {
        for(let an of echantillon.analyses) {
          for(let analyse of this.analyses) {
            if(an === analyse.value) {
              anl.push(analyse);
            }
          }
        }
      }
      return anl;
    },
    getLibelle(code, info) {
      let data = [], libelle = "";
      if(info === 'famille') {
        data = this.listFamille ? this.listFamille : [];
      }
      else if(info === 'sousfamille') {
        data = this.sousFamilles ? this.sousFamilles : [];
      }
      else if(info === 'lmr') {
        data = this.listLmrs ? this.listLmrs : [];
      }
      else if(info === 'unite') {
        data = this.listUnites ? this.listUnites : [];
      }
      for(let item of data) {
        if(item.value === code) {
          libelle = item.text;
        }
      }
      return libelle;
    },
    resetEchantillon() {
      this.famille = "";
      this.sousFamille  =  "";
      this.unite =  "";
      this.lmr =  "";
      this.dateFabTime = "00:00";
      this.datePrevTime = "00:00";
      this.echantillon = {
        numero: null,
        commande_id: null,
        reference: "",
        lot: "",
        identification: "",
        famille: "",
        sous_famille: "",
        quantite: "",
        unite: "",
        commentaire: "",
        date_creation: this.getFormatedDate(new Date()),
        date_fabrication: null,
        date_prelevement: null,
        date_dlc: null,
        date_ddm: null,
        lmr: "",
        analyses: [],
        info_1: "",
        info_2: "",
        info_3: "",
        info_4: ""
      };
      this.edition = true;
    },
    addOtherEchantillon() {
      if(this.$route.params.cid) {
        this.resetEchantillon();
        this.$router.push("/echantillon/" + this.$route.params.cid + "/");
      }
    },
    duplicateEchantillon() {
      let cid = this.echantillon.commande_id;
      let famille = this.echantillon.famille;
      let sousFamile = this.echantillon.sous_famille;
      let lmr = this.echantillon.lmr;
      let analyses = this.echantillon.analyses;
      this.resetEchantillon();
      this.echantillon.commande_id = cid;
      this.echantillon.famille = famille;
      this.echantillon.sous_famille = sousFamile;
      this.echantillon.lmr = lmr;
      this.echantillon.analyses = analyses;
      this.edition = true;
      this.$router.push("/echantillon/" + cid + "/");
    },
    checkDates() {
      if(this.echantillon.date_fabrication && this.echantillon.date_fabrication.length <= 10) {
        let timef = this.dateFabTime ? this.dateFabTime + ":00" : "00:00:00";
        let datef = this.echantillon.date_fabrication + " " + timef;
        this.echantillon.date_fabrication = datef;
      }
      else {
        this.dateFabTime = "";
      }
      if(this.echantillon.date_prelevement && this.echantillon.date_prelevement.length <= 10) {
        let timep = this.datePrevTime ? this.datePrevTime + ":00" : "00:00:00";
        let datep = this.echantillon.date_prelevement + " " + timep;
        this.echantillon.date_prelevement = datep;
      }
      else {
        this.datePrevTime = "";
      }
    },
    addCodeAnalyses(analyses) {
      let analyseCodes = Object.assign(analyses);
      for(let i=0; i < analyseCodes.length; i++) {
        analyseCodes[i].text = analyseCodes[i].text + ` (${analyseCodes[i].value}) `;
      }
      return analyseCodes;
    },
    confirmDialog(isToConfrim) {
      if(this.isToDelete && isToConfrim) {
        this.deleteEchantillon();
        this.isToDelete = false;
        return;
      }
      else if(this.isToDelete && !isToConfrim) {
        this.isToDelete = false;
        return;
      }
    },
    askDeleteEchantillon() {
      this.message = "Etes-vous sûr de vouloir supprimer cet échantillon ?";
      this.isToDelete = true;
    },

    lockCommande() {
      this.commande.date_envoi = moment(new Date()).format("YYYY-MM-DD");
      this.validateCommande();
    },

    async validateCommande() {
      if(!this.commande.id) {
        this.$toast.error("Vous ne pouvez pas valider cette commande");
        return;
      }
      let creationDate = moment(new Date()).format("YYYY-MM-DD");
      if (moment(this.commande.date_prev_reception).isBefore(moment(creationDate))) {
        this.$toast.warning(
          "La date prévisionnelle de réception doit être superieur à la date d'aujourd'hui."
        );
        return;
      }

      this.LOADING(true);
      let echantillons = this.commande.echantillons;
      delete this.commande.echantillons;
      let response = await updateSrv.validateCommande(this.user.token, this.commande);
      this.LOADING(false);

      if (response.success) {
        this.commande = response.commande;
        this.setCurrentCommande(this.commande);
        this.commande.echantillons = echantillons;
      }
      else {
        if(response.wrg) {
          this.$toast.warning(response.error);
        }
        else {
          this.$toast.error(response.error);
        }
      }
    },

    async getEchantillon() {
      if (!this.$route.params.cid || !this.$route.params.id) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getEchantillionCommande(this.user.token, this.$route.params.cid, this.$route.params.id);
      this.LOADING(false);

      if (response.success) {
        this.echantillon = response.echantillon;
        if(this.analyses && this.analyses.length > 0) {
          this.analysesInfo = this.getInfoAnalyses(this.echantillon);
        }
        this.dateFabTime = this.getEditedTime(this.echantillon.date_fabrication);
        this.datePrevTime = this.getEditedTime(this.echantillon.date_prelevement);

        this.famille = this.getLibelle(this.echantillon.famille, 'famille');
        this.sousFamille = this.getLibelle(this.echantillon.sous_famille, 'sousfamille');
        this.lmr = this.getLibelle(this.echantillon.lmr, 'lmr');
        this.unite = this.getLibelle(this.echantillon.unite, 'unite');

        this.edition = false;
      } else {
        this.$toast.error(response.error);
      }
    },

    async getFamilles() {
      if (this.listFamille && this.listFamille.length > 0) {
        this.familles = this.listFamille;
        this.famille = this.getLibelle(this.echantillon.famille, 'famille');
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getFamilles(this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.familles = this.getMappedItems(response.familles);
        this.setListFamille(this.familles);
        this.famille = this.getLibelle(this.echantillon.famille, 'famille');
      } else {
        this.$toast.error(response.error);
      }
    },

    async getSousFamilles() {
      if(!this.echantillon.famille) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getSousFamilles(this.user.token, this.echantillon.famille);
      this.LOADING(false);

      if (response.success) {
        this.sousFamilles = this.getMappedItems(response.sousFamilles, 'sousfamiles');
        this.sousFamille = this.getLibelle(this.echantillon.sous_famille, 'sousfamille');
      } else {
        this.$toast.error(response.error);
      }
    },

    async getUnites() {
      if (this.listUnites && this.listUnites.length > 0) {
        this.unites = this.listUnites;
        this.unite = this.getLibelle(this.echantillon.unite, 'unite');
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getUnites(this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.unites = this.getMappedItems(response.unites);
        this.setListUnites(this.unites);
        this.unite = this.getLibelle(this.echantillon.unite, 'unite');
      } else {
        this.$toast.error(response.error);
      }
    },

    async getInfos() {
      if (!this.$route.params.cid) {
        return;
      }

      if(this.listInfos && Object.keys(this.listInfos).length) {
        this.infos = this.listInfos;
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getInfos(this.user.token, this.$route.params.cid);
      this.LOADING(false);

      if (response.success) {
        this.setListInfos(response.infos);
        this.infos = response.infos;
      } else {
        this.$toast.error(response.error);
      }
    },

    async getLmrs() {
      if (this.listLmrs && this.listLmrs.length > 0) {
        this.lmrs = this.listLmrs;
        this.lmr = this.getLibelle(this.echantillon.lmr, 'lmr');
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getLmrs(this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.lmrs = this.getMappedItems(response.lmrs);
        this.setListLmrs(this.lmrs);
        this.lmr = this.getLibelle(this.echantillon.lmr, 'lmr');
      } else {
        this.$toast.error(response.error);
      }
    },

    async saveEchantillon() {
      if(!this.checkMandatoryFields()) {
        return;
      }
      this.echantillon.commande_id = this.$route.params.cid;
      this.echantillon = this.setEmptyFields(this.echantillon);
      this.checkDates();

      this.LOADING(true);
      let response = await updateSrv.saveEchantillon(this.user.token, this.$route.params.cid, this.echantillon);
      this.LOADING(false);

      if (response.success) {
        this.edition = false;
        this.echantillon = response.echantillon;
        this.analysesInfo = this.getInfoAnalyses(this.echantillon);
        this.famille = this.getLibelle(this.echantillon.famille, 'famille');
        this.sousFamille = this.getLibelle(this.echantillon.sous_famille, 'sousfamille');
        this.lmr = this.getLibelle(this.echantillon.lmr, 'lmr');
        this.unite = this.getLibelle(this.echantillon.unite, 'unite');
        this.$router.push("/echantillon/" + this.$route.params.cid + "/" + this.echantillon.numero);
      } else {
        this.$toast.error(response.error);
      }
    },

    async updateEchantillon() {
      if(!this.checkMandatoryFields()) {
        return;
      }
      this.echantillon = this.setEmptyFields(this.echantillon);
      this.checkDates();

      this.LOADING(true);
      let response = await updateSrv.updateEchantillon(this.user.token, this.$route.params.cid, this.echantillon);
      this.LOADING(false);

      if (response.success) {
        this.edition = false;
        this.echantillon = response.echantillon;
        this.analysesInfo = this.getInfoAnalyses(this.echantillon);
        this.famille = this.getLibelle(this.echantillon.famille, 'famille');
        this.sousFamille = this.getLibelle(this.echantillon.sous_famille, 'sousfamille');
        this.lmr = this.getLibelle(this.echantillon.lmr, 'lmr');
        this.unite = this.getLibelle(this.echantillon.unite, 'unite');
      } else {
        this.$toast.error(response.error);
      }
    },

    async deleteEchantillon() {
      if(!this.echantillon.numero) {
        this.$router.push("/commande/" + this.$route.params.cid);
        return;
      }

      this.LOADING(true);
      let response = await updateSrv.deleteEchantillon(this.user.token, this.echantillon);
      this.LOADING(false);

      if (response.success) {
        this.$router.push("/commande/" + this.$route.params.cid);
      } else {
        this.$toast.error(response.error);
      }
    },

    async getAnalyses() {
      if(this.listAnalyses && this.listAnalyses.length > 0) {
        let ana = JSON.parse(JSON.stringify(this.listAnalyses));
        this.analyses = this.addCodeAnalyses(ana);
        this.analysesInfo = this.getInfoAnalyses(this.echantillon);
        return;
      }

      if(!this.$route.params.cid) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getAnalyses(this.user.token, this.$route.params.cid);
      this.LOADING(false);

      if (response.success) {
        let analyses = this.getFormatedAnalyses(response.analyses);
        let ana = JSON.parse(JSON.stringify(analyses));
        this.analyses = this.addCodeAnalyses(analyses);
        this.setListAnalyses(ana);

        if(this.echantillon.analyses && this.echantillon.analyses.length > 0) {
          this.analysesInfo = this.getInfoAnalyses(this.echantillon);
        }
      } else {
        this.$toast.error(response.error);
      }
    },

    async getCommande() {
      if (!this.$route.params.cid) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getCommande(this.user.token, this.$route.params.cid);
      this.LOADING(false);

      if (response.success) {
        this.commande = response.commande;
        this.setCurrentCommande(this.commande);
      } else {
        this.$toast.error(response.error);
      }
    },
  }
};
</script>

<style scoped>
.cmd-title {
  color: #2196f3;
  font-size: 1.5em;
}
.read-created {
  padding-top: 25px;
}
.codebare svg {
  vertical-align: middle;
  max-width: 180px;
  max-height: 80px;
}
.com-icon {
  padding: 0px 10px 0px 10px;
  margin-bottom: 10px;
}
.print-btn {
  float: right;
}
.ana-title {
  font-weight: 700;
}
.read-label {
  font-weight: 500;
  padding-right: 10px;
}
.ana-price {
  float: right;
}
.v-list-item {
  min-height: 0px;
}
.v-list-item__content {
  padding: 0px;
}
.v-list-item__action {
  margin: 0px;
}
.analyse-libelle {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
}
.previous-btn {
  margin: 14px 10px 10px 10px;
}
</style>
