export default {
    // le module est réutilisable.
    namespaced: true,
    state: {
      appVersion: process.env.VUE_APP_VERSION
    },
  
    getters: {
      appVersion: state => state.appVersion
    }
  };
  