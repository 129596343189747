import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';

import App404 from '@/components/common/App404';
import AppUnauthorized from '@/components/common/AppUnauthorized';
import Commandes from '@/components/commandes/commandes';
import Commande from '@/components/commande/commande';
import Echantillon from '@/components/echantillon/echantillon';
import Resultats from '@/components/echantillons/AppEchantillons';

import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Home
  },
  {
    path: '/commandes',
    name: 'Commandes',
    component: Commandes
  },
  {
    path: '/commande/:id?',
    name: 'Commande',
    component: Commande
  },
  {
    path: '/echantillon/:cid/:id?',
    name: 'Echantillon',
    component: Echantillon
  },
  {
    path: '/resultats/:cid',
    name: 'Resultats',
    component: Resultats
  },
  {
    path: '/echantillons',
    name: 'Echantillons',
    component: Resultats
  },
  {
    path: '/404',
    name: '404',
    component: App404
  },
  {
    path: '/unauthorized',
    name: 'Non Autorisé',
    component: AppUnauthorized,
    meta: { title: 'Service inaccessible' }
  },
  {
    path: '*',
    redirect: '/'
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // Redirige vers la page d'accueil s'il n'y a pas de tiers de sélectionné et si la page courante n'est pas déjà la page d'accueil.
  if (!store.getters['auth/user'].login && to.name !== 'Accueil' && to.name !== 'Non Autorisé') {
    next('/');
  }

  if (to.name === 'Non Autorisé') {
    document.title = to.meta.title;
    next();
  }

  // Si un tiers est déjà choisi et retour à la page d'accueil, alors on applique un switchTiers.
  if (store.getters['auth/user'].login && to.name === 'Accueil') {
    store.commit('data/setIsOKCommande', false);
    store.commit('auth/SWITCHTIERS', {
      login:  '',
      name:  '',
      email: store.getters['auth/user'].email,
      token: store.getters['auth/user'].token
    });
    next('/');
  }

  next();
});


export default router;
