<template>
  <v-menu
    v-model="menuDate"
    :close-on-content-click="false"
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :label="label"
        :value="cDateFr"
        append-icon="event"
        readonly
        v-on="on"
        @click:clear="mClearDate"
      />
    </template>
    <v-date-picker
      v-model="date"
      first-day-of-week="1"
      locale="fr-FR"
      no-title
      @input="menuDate = false"
    />
  </v-menu>
</template>

<script>
import moment from 'moment';
import 'moment/locale/fr';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    initialDate: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      date: this.initialDate,
      menuDate: false
    };
  },

  computed: {
    /**
     * Retourne la date au format DD/MM/YYYY
     */
    cDateFr () {
      let res = '';
      if (this.date) {
        this.$emit('dateChange', this.date);
        res = moment(this.date).format('L');
      }

      return res;
    }
  },

  watch: {
    initialDate(val) {
      this.date = val;
    }
  },

  methods: {
    mClearDate () {
      this.date = '';
      this.$emit('dateChange', this.date);
    }
  }
};
</script>

<style>

</style>
