<template>
  <div>
    <v-app-bar
      color="primary"
      dark
      fixed
      app
      height="40"
    >
      <a href="/">
        <img class="logo" src="@/assets/logo.png" alt="logo">
      </a>
      <v-toolbar-title>Extranet Capinov <small class="text-caption"> (v{{ appVersion }})</small></v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn v-if="user.login" text class="switch" @click="mSwitchTiers()">
          <v-icon larger title="Changer de Tiers">
            swap_horizontal_circle
          </v-icon>
        </v-btn>
        <v-btn text class="help" @click="goToHelp">
          <v-icon larger title="Aide">
            fas fa-question-circle
          </v-icon>
        </v-btn>
        <v-btn v-if="user.login" text class="help" @click="goTo('echantillons')">
          <v-icon larger title="Echantillons">
            fas fa-vials
          </v-icon>
        </v-btn>
        <v-btn v-if="isLogin && showCommande" text :href="precapUrl" target="_blank">
          <v-icon larger title="Quick Commande">
            fas fa-file-alt
          </v-icon>
        </v-btn>
        <v-tooltip v-if="isLogin && user.login" bottom>
          <template #activator="{ on }">
            <v-icon class="user" larger v-on="on">
              fas fa-user-circle
            </v-icon>
          </template>
          <div>
            <div class="name">
              {{ user.name }}
              <span v-if="user.firstName">{{ user.firstName }} </span>
            </div>
            <div class="login">
              {{ user.login }}
            </div>
          </div>
        </v-tooltip>
        <v-btn
          v-if="isLogin"
          text
          class="help"
          :href="cgusUrl"
          target="_blank"
        >
          <v-icon larger title="CGUs">
            fas fa-file-contract
          </v-icon>
        </v-btn>
        <v-btn v-if="isLogin" text @click="logout">
          <v-icon title="logout">
            exit_to_app
          </v-icon>
          Se Déconnecter
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import keycloakSrv from '@/services/keycloakService';
import searchSrv from "@/services/searchService";

export default {
  data() {
    return {
      cgusUrl: '',
      precapUrl: '',
      showCommande: false
    };
  },

  computed: {
    ...mapGetters('auth', ['isLogin', 'user', 'status']),
    ...mapGetters('data', ['isOKCommande']),
    ...mapGetters('common', ['appVersion']),
  },

  watch: {
    isOKCommande(newVal, oldVal) {
      if(oldVal !== newVal) {
        this.showCommande = newVal;
      }
    },
    status(newVal, oldVal) {
      if(newVal === 'unauthorized') {
        this.goTo('unauthorized');
      }
    }
  },

  created() {
    this.showCommande = this.isOKCommande;
    this.mGetEnvs();
  },

  methods: {
    ...mapMutations('data', ['setListAllContrats', 'setListContratsActifs', 'setLastEchVisited', 'setListFamille', 'setListUnites', 'setListAnalyses', 'setListInfos',  'setListLmrs', 'setCurrentCommande', 'setIsOKCommande']),
    ...mapActions('auth', { store_logout: 'logout', switch_tiers: 'switchTiers' }),
    ...mapMutations('loading', ['LOADING']),

    resetStore() {
      this.setListAllContrats([]);
      this.setListContratsActifs([]);
      this.setLastEchVisited({});
      this.setListFamille([]);
      this.setListUnites([]);
      this.setListInfos([]);
      this.setListAnalyses([]);
      this.setListLmrs([]);
      this.setCurrentCommande({});
      this.setIsOKCommande(false);
    },

    goToHelp() {
      window.open(
        "https://docs.google.com/document/d/1S6MQvUecxrmMKV5BlVK37RUkS9N9KETWW_nhq2Hlnic/edit#heading=h.dclegjfi55kd"
      );
    },

    goTo(page) {
      this.$router.push("/" + page + "/");
    },

    async logout() {
      this.store_logout();
      this.resetStore();
      keycloakSrv.logout();
    },

    /**
     * Appel le service permettant de récupérer des variables d'environnement depuis le back.
     */
    async mGetEnvs() {
      let response = await searchSrv.getEnvs(this.user.token);

      if (response.success) {
        this.cgusUrl = response.cgusUrl;
        this.precapUrl = response.precapUrl;
      } else {
        this.$toast.error(response.error);
      }
    },

    mSwitchTiers() {
      this.switch_tiers({
        login:  '',
        name:  '',
        email: this.user.email,
        token: this.user.token
      });
      this.resetStore();
      this.goTo();
    }
  }
};
</script>

<style>
.logo {
  height: 35px;
  margin-right: 5px;
  margin-top: 8px;
  border-radius: 10%;
}
.name {
  color: #eeeeee;
  font-size: 14px;
  font-weight: 500;
}
.login {
  color: #eeeeee;
  font-size: 12px;
}
.help {
  margin-right: -15px;
}
.user {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
