<template>
  <v-container>
    <span class="bg" />
    <center>
      <v-img
        :src="require('@/assets/capinov-logo.png')"
        width="200px"
      />
    </center>
    <v-card
      class="mx-auto my-12 py-4 rounded-12"
      elevation="2"
      max-width="446"
      color="#8a8a8d"
    >
      <v-card-text class="text-center">
        <h2
          class="font-weight-black white--text"
          color="#FFFFFF"
        >
          Ce service n'est pas accessible.
        </h2>
      </v-card-text>
      <v-card-actions class="justify-center mt-4">
        <v-btn
          class="white--text"
          color="#7ab737"
          @click="logout()"
        >
          Se reconnecter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import keycloakSrv from '@/services/keycloakService';
import mixins from '@/mixins/mixins.js';

export default {
  name: 'AppUnauthorized',
  mixins: [mixins],

  methods: {
    ...mapActions('auth', { store_logout: 'logout' }),

    logout () {
      this.store_logout();
      keycloakSrv.logout();
    },
  },
};
</script>

<style>
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(#ffffff, #ffffffbf), url( '~@/assets/capinov-bg.jpg');
    background-position:center center, center center;
    background-repeat: no-repeat, no-repeat;
    background-attachment: fixed, fixed;
    background-size: cover, cover;
  }

  .rounded-12 {
    border-radius: 12px;
  }
</style>
