"use strict";

import Vue from 'vue';
import axios from 'axios';
import env from '@/config/env';
import keycloakSrv from '@/services/keycloakService';

import store from '@/store';

let config = {
  baseURL: process.env.NODE_ENV === 'development' ? env.api.local : env.api.url,
  timeout: 500 * 1000
};

const _axios = axios.create(config);

/**
 * Intercepte les requêtes avant qu'elles ne soient traîtées par then ou catch.
 */
_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

/**
 * Intercepte les réponses avant qu'elles ne soient traîtées par then ou catch.
 */
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data

    // Vérification des droits du rôle de l'utilisateur
    let meta = response && response.data ? response.data.meta : null;
    if (meta && meta.error_code === 14) {
      store.dispatch('auth/status', 'unauthorized');
      store.dispatch('auth/logout');
      response.error = true;
    }

    return response;
  },
  function(error) {
    // Si l'authentification n'est pas bon côté serveur.
    if (error.response && error.response.status === 401) {
      // Appel de l'action logout du module auth du store.
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin);

export default Plugin;
