<template>
  <v-container grid-list-md>
    <v-card>
      <v-form ref="formRech" v-model="formRechValid">
        <v-container ma-0 class="container-up">
          <v-layout wrap>
            <v-flex xs4>
              <v-text-field v-model="echantillonRech" label="Echantillon" />
            </v-flex>
            <v-flex xs4>
              <app-date
                v-model="dateDe"
                :initial-date="dateDe"
                label="De"
                @dateChange="mChangeDateDe"
              />
            </v-flex>
            <v-flex xs4>
              <app-date
                v-model="dateA"
                :initial-date="dateA"
                :disabled="!dateDe"
                label="A"
                @dateChange="mChangeDateA"
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="refCommandeRech"
                label="Référence de commande"
              />
            </v-flex>
            <v-flex xs4>
              <input-rech
                v-model="produitRech"
                champ="produits"
                item-text="complet"
                item-value="produit"
                label="Liste des produits"
                @rechChange="mChangeProduitRech"
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="idEchantillonRech"
                label="Identification échantillon"
              />
            </v-flex>
            <v-flex xs4>
              <input-rech
                v-model="donneurRech"
                champ="donneurs"
                item-text="ref"
                label="Tiers donneur d'ordre"
                @rechChange="mChangeDonneurRech"
              />
            </v-flex>
            <v-flex xs4>
              <input-rech
                v-model="proprioRech"
                champ="proprietaires"
                item-text="ref"
                label="Tiers propriétaire"
                @rechChange="mChangeProprioRech"
              />
            </v-flex>
            <v-flex xs4>
              <input-rech
                v-model="payeurRech"
                champ="payeurs"
                item-text="ref"
                label="Tiers payeur"
                @rechChange="mChangePayeurRech"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
      <v-card-actions class="actions">
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn
              :disabled="echantillons.length === 0"
              color="primary"
              dark
              v-on="on"
            >
              <span class="text-btn">Export échantillons</span>
              <v-icon class="export">
                fas fa-sort-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="mExportEchantillons(true)">
              <v-list-item-title>
                <v-icon left color="orange darken-2">
                  fas fa-download
                </v-icon>
                <span class="text-sub-btn">1 ligne par résultat</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="mExportEchantillons(false)">
              <v-list-item-title>
                <v-icon left color="orange darken-2">
                  fas fa-download
                </v-icon>
                <span class="text-sub-btn">1 ligne par échantillon</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-radio-group
          v-if="echantillons.length > 0"
          v-model="typeDoc"
          class="type-doc"
          row
        >
          <v-radio label="Excel" value="excel" />
          <v-radio label="Sheet" value="sheet" />
        </v-radio-group>
        <v-spacer />
        <span
          v-if="isTooBigMsg && echantillons.length > 0 && !isZipBig"
          class="warning-msg"
        >
          Les résultats affichés sont partiels !
        </span>
        <v-spacer />
        <v-btn
          :disabled="allEchPdf.length === 0"
          color="accent"
          @click="getAllPdfs"
        >
          <v-icon size="16" class="icon-pdf">
            fas fa-file-pdf
          </v-icon>
          <span class="text-btn">Télécharger tous les PDFs</span>
        </v-btn>&nbsp;
        <v-btn
          :disabled="!activeRearch"
          color="primary"
          @click="mGetEchantillons"
        >
          <span class="text-btn">Rechercher</span>
        </v-btn>&nbsp;
        <v-btn color="error" ml-50 @click="mResetFormRech">
          <span class="text-btn">Réinitialiser</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <list-echantillons
      v-if="echantillons.length > 0"
      :echantillons="echantillons"
      :header-echantillons="headerEchantillons"
      :is-too-big="isTooBig"
      :obj-recherche="objRecherche"
    />
    <app-dialog
      :active="isTooBig"
      :message="isZipBig ? 'Le fichier à télécharger est trop volumineux: Veuillez affiner votre recherche.': 'Trop de résultats : seul une partie est retournée. Veuillez affiner votre recherche.'"
      @switchDialog="switchDialog"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapMutations, mapGetters } from 'vuex';

import AppDialog from '@/components/common/AppDialog';
import AppDate from '@/components/common/AppDate';
import InputRech from '@/components/echantillons/InputRech';
import ListEchantillons from '@/components/echantillons/list/ListEchantillons';
import searchSrv from '@/services/searchService';

export default {
  name: 'AppEchantillons',
  components: {
    AppDate,
    InputRech,
    ListEchantillons,
    AppDialog
  },

  data() {
    return {
      dateA: null,
      dateDe: null,
      donneurRech: null,
      echantillonRech: null,
      echantillons: [],
      headerEchantillons: [],
      idEchantillonRech: null,
      isTooBig: false,
      isTooBigMsg: false,
      isZipBig: false,
      formRechValid: false,
      activeRearch: false,
      objRecherche: null,
      payeurRech: null,
      produitRech: null,
      proprioRech: null,
      refCommandeRech: null,
      typeDoc: 'excel',
      allEchPdf: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['user', 'isLogin']),
    showAlert () {
      return this.echantillons.length > 0;
    }
  },

  watch: {
    dateA (val) {
      if (!val) {
        return;
      }

      if (moment(val).isBefore(moment(this.dateDe))) {
        this.formRechValid = false;
        this.activeRearch = false;
        this.$toast.warning('La date de fin ne peut pas être inférieur à la date de début');
      }
      else if (moment(val).diff(moment(this.dateDe), 'months', true) > 6) {
        this.formRechValid = false;
        this.activeRearch = false;
        this.$toast.warning('L\'intervalle entre les deux dates dépasse les 6 mois');
      }
      else {
        this.formRechValid = true;
        this.activeRearch = true;
      }
    },
    dateDe (val) {
      let valDateA = this.dateA;
      if (!valDateA) {
        valDateA = moment();
      }

      if (moment(valDateA).isBefore(moment(val))) {
        this.formRechValid = false;
        this.activeRearch = false;
        this.$toast.warning('La date de fin ne peut pas être inférieur à la date de début');
      }
      else if (moment(valDateA).diff(moment(val), 'months', true) > 6) {
        this.formRechValid = false;
        this.activeRearch = false;
        this.$toast.warning('L\'intervalle entre les deux dates dépasse les 6 mois');
      }
      else if(val) {
        this.formRechValid = true;
        this.activeRearch = true;
      }
    },
    echantillonRech: function (newVal, oldVal) {
      if (this.oldVal !== newVal) {
        this.activeRearch = true;
        this.echantillons = [];
      }
    },
    refCommandeRech: function (newVal, oldVal) {
      if (this.oldVal !== newVal) {
        this.activeRearch = true;
        this.echantillons = [];
      }
    },
    idEchantillonRech: function (newVal, oldVal) {
      if (this.oldVal !== newVal) {
        this.activeRearch = true;
        this.echantillons = [];
      }
    }
  },

  created() {
    let these = this;
    window.addEventListener('keyup', function (e) {
      let path = these.$route.fullPath;
      if (e.key === 'Enter' && path === "/" && these.activeRearch) {
        these.mGetEchantillons();
      }
    }, true);


    if(this.$route.params.cid) {
      this.getResultsCommande();
    }
  },

  methods: {
    // Mapping des mutations du store.
    ...mapMutations('loading', ['LOADING']),

    mChangeDateDe(pDateDe) {
      if (this.dateDe !== pDateDe) {
        this.activeRearch = true;
        this.echantillons = [];
        this.allEchPdf = [];
      }
      this.dateDe = pDateDe || null;
    },

    mChangeDateA(pDateA) {
      if (this.dateA !== pDateA) {
        this.activeRearch = true;
        this.echantillons = [];
        this.allEchPdf = [];
      }
      this.dateA = pDateA || null;
    },

    mChangeDonneurRech (pDonneurRech) {
      if (!this.donneurRech || (this.donneurRech && pDonneurRech && this.donneurRech.ref !== pDonneurRech.ref)) {
        this.activeRearch = true;
        this.echantillons = [];
        this.allEchPdf = [];
      }
      this.donneurRech = pDonneurRech;
    },

    mChangeProduitRech (pProduitRech) {
      if (!this.produitRech || (this.produitRech && pProduitRech && this.produitRech.produit !== pProduitRech.produit)) {
        this.activeRearch = true;
        this.echantillons = [];
        this.allEchPdf = [];
      }
      this.produitRech = pProduitRech;
    },

    mChangeProprioRech (pProprioRech) {
      if (!this.proprioRech || (this.proprioRech && pProprioRech && this.proprioRech.ref !== pProprioRech.ref)) {
        this.activeRearch = true;
        this.echantillons = [];
        this.allEchPdf = [];
      }
      this.proprioRech = pProprioRech;
    },

    mChangePayeurRech (pPayeurRech) {
      if (!this.payeurRech || (this.payeurRech && pPayeurRech && this.payeurRech.ref !== pPayeurRech.ref)) {
        this.activeRearch = true;
        this.echantillons = [];
        this.allEchPdf = [];
      }
      this.payeurRech = pPayeurRech;
    },

    switchDialog(value) {
      this.isTooBig = value;
      this.isTooBigMsg = true;
    },

    mTestFormChampsVides() {
      return !this.dateA && !this.dateDe && !this.donneurRech && !this.echantillonRech && !this.payeurRech && !this.produitRech && !this.proprioRech && !this.refCommandeRech && !this.idEchantillonRech;
    },

    addElementPDf(ech, type) {
      if(type) {
        this.allEchPdf.push({
          id: ech.echantillon,
          type: type
        });
      }
    },

    prepareAllPdfs(echantillons) {
      for(let ech of this.echantillons) {
        let type1 = ech.annexe ? 'annexe': '';
        let type2 = ech.rapport ? 'rapport': '';
        let type3 = ech['accuse_reception'] ? 'accuse-reception' : '';
        this.addElementPDf(ech, type1);
        this.addElementPDf(ech, type2);
        this.addElementPDf(ech, type3);
      }
    },

    async getAllPdfs() {
      if (!this.allEchPdf.length > 0) {
        this.$toast.error('Pas de pdf à télécharger.');
        return;
      }

      this.isTooBig = false;
      this.isZipBig = false;

      // Mutation du state loading.
      this.LOADING(true);

      let response = await searchSrv.getAllPdfs(this.allEchPdf, this.user.token, this.user.login);
      if (response.success) {

        let fileName = response.filename ? response.filename : moment(new Date()).format('YYYYMMDD-h:mm:ss');

        // Formattage et création du Blob depuis le flux pdf.
        const file = new Blob (
          [response.data],
          { type: 'application/zip' }
        );

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(file, fileName);
        }
        else {   // for Non-IE (chrome, firefox etc.)
          let url = URL.createObjectURL(file);

          var downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = fileName;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
      else if(response.error === "Fichier trop volumineux") {
        this.isTooBig = true;
        this.isZipBig = true;
      }
      else {
        this.$toast.error(response.error);
      }
      this.LOADING(false);
    },

    async getResultsCommande() {

      this.isTooBigMsg = false;

      this.objRecherche = {
        "date_debut": "",
        "date_fin": "",
        "donneur_ordre": null,
        "id_echantillon": null,
        "payeur": null,
        "produit": null,
        "proprietaire": null,
        "ref_commande": null,
        "ref_echantillon": "."+this.$route.params.cid+".",
      };

      this.LOADING(true);
      let response = await searchSrv.getEchantillons(this.objRecherche, this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.isTooBig = response.isTooBig;
        this.isZipBig = false;
        this.activeRearch = false;

        if (response.warn) {
          this.$toast.warning(response.msg);
        }
        else if(response.msg) {
          this.$toast.success(response.msg);
        }

        this.echantillons = response.echantillons;
        this.headerEchantillons = response.analyses;
      }
      else {
        if (response.warn) {
          this.$toast.warning(response.msg);
        }
        else {
          this.$toast.error(response.msg);
        }
      }
    },

    /**
     * Cherche les échantillons.
     */
    async mGetEchantillons () {
      if (this.mTestFormChampsVides()) {
        this.$toast.warning('Au moins un champ doit être saisi !');
        return;
      }

      if (this.echantillonRech && this.echantillonRech.length < 4) {
        this.$toast.warning('Echantillon doit avoir au minimum 4 caractères');
        return;
      }
      if (this.refCommandeRech && this.refCommandeRech.length < 4) {
        this.$toast.warning('Référence de commande doit avoir au minimum 4 caractères');
        return;
      }
      if (this.idEchantillonRech && this.idEchantillonRech.length < 4) {
        this.$toast.warning('Identification échantillion doit avoir au minimum 4 caractères');
        return;
      }

      this.isTooBigMsg = false;

      // Mutation du state loading.
      this.LOADING(true);

      // Initialise l'objet recherché.
      this.objRecherche = this.mGetObjRech();

      let response = await searchSrv.getEchantillons(this.objRecherche, this.user.token);

      this.LOADING(false);

      if (response.success) {
        this.isTooBig = response.isTooBig;
        this.isZipBig = false;
        this.activeRearch = false;

        if (response.warn) {
          this.$toast.warning(response.msg);
        }
        else if(response.msg) {
          this.$toast.success(response.msg);
        }

        this.echantillons = response.echantillons;
        this.headerEchantillons = response.analyses;
        this.prepareAllPdfs(this.echantillons);
      }
      else {
        if (response.warn) {
          this.$toast.warning(response.msg);
        }
        else {
          this.$toast.error(response.msg);
        }
      }
    },

    /**
     * Exportation des résultats au format CSV.
     * @param {boolean} parResultat
     */
    async mExportEchantillons (parResultat) {

      // Mutation du state loading.
      this.LOADING(true);
      let response = await searchSrv.getExportedEchantillons(parResultat, this.typeDoc, this.objRecherche, this.user.token);

      if (response.success) {

        let fileName = `Export_échantillons_${moment(new Date()).format('L')}.csv`;
        if(this.echantillons.length === 1) {
          fileName = `${this.echantillons[0].echantillon}.csv`;
        }

        const file = new Blob(
          [response.data],
          { type: 'text/csv' }
        );

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(file, fileName);
        }
        else {   // for Non-IE (chrome, firefox etc.)
          let url = URL.createObjectURL(file);

          var downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = fileName;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
      else {
        this.$toast.error(response.error);
      }

      this.LOADING(false);
    },

    /**
     * Retourne l'objet recherché à envoyer au back.
     * @return {Object}
     */
    mGetObjRech () {
      return {
        date_debut: this.dateDe,
        date_fin: this.dateA,
        donneur_ordre: this.donneurRech ? this.donneurRech.ref : null,
        id_echantillon: this.idEchantillonRech,
        payeur: this.payeurRech ? this.payeurRech.ref : null,
        produit: this.produitRech ? this.produitRech.produit : null,
        proprietaire: this.proprioRech ? this.proprioRech.ref : null,
        ref_echantillon: this.echantillonRech,
        ref_commande: this.refCommandeRech,
      };
    },

    /**
     * Vide les champs du formulaire de recherche.
     */
    mResetFormRech() {
      this.$refs.formRech.reset();
      this.formRechValid = false;
      this.activeRearch = false;
      this.dateA = null;
      this.dateDe = null;
      this.donneurRech = null;
      this.echantillons = [];
      this.payeurRech = null;
      this.produitRech = null;
      this.proprioRech = null;
      this.allEchPdf = [];
    }
  }
};
</script>

<style>
@media (min-width: 1496px) {
  .container {
    max-width: 1490px;
  }
}
.container-up {
  padding: 12px 12px 2px 12px;
}
.v-text-field {
  padding-top: 6px;
  margin-top: 0px;
}
.v-input input {
  font-size: 14px;
}
.text-btn {
  font-size: 12px;
}
.text-sub-btn {
  font-size: 13px;
  font-weight: 500;
}
.v-data-table td,
.v-data-table th {
  padding: 0 12px;
}
.v-data-table td {
  font-size: 12px;
  line-height: 1.2;
}
.export {
  margin-left: 8px;
  margin-bottom: 8px;
}
.v-label {
  font-size: 13px;
}
.warning-msg {
  padding-top: 10px;
  text-align: end;
  font-size: 12px;
  font-weight: 500;
  color: red;
}
.type-doc {
  margin-bottom: -12px;
}
.type-doc .v-icon.v-icon {
  margin-left: 10px;
}
.type-doc .v-icon.v-icon {
  font-size: 16px;
}
.type-doc .v-input--radio-group--column {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}
.type-doc .v-input--selection-controls__ripple {
  height: 25px;
  width: 25px;
  left: -2px;
  top: calc(50% - 20px);
}
.icon-pdf {
  width: 16px;
  margin-right: 5px;
}
</style>
