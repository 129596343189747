<template>
  <v-autocomplete
    v-model="select"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-no-data
    return-object
    @change="mEmitChange"
  />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import searchSrv from '@/services/searchService';

export default {
  name: 'InputRech',
  props: {
    champ: {
      type: String,
      required: true,
      validator: v => ['donneurs', 'payeurs', 'produits', 'proprietaires'].indexOf(v) !== -1
    },
    itemText: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      items: [],
      search: null,
      select: null
    };
  },

  computed: {
    ...mapGetters('auth', ['user', 'isLogin']),
  },

  watch: {
    search (val) {
      if (val && val.length > 2) {
        // Evite de refaire une recherche après sélection d'un élément.
        if (this.select) {
          const tCompare = (this.champ === 'produits') ? this.select.complet : this.select.ref;
          if (val === tCompare) {
            return;
          }
        }

        this.mGetDataDebounced();
      }
    }
  },

  methods: {
    ...mapMutations('loading', ['LOADING']),

    /**
     * Emet l'évènement "rechChange" une fois un tiers sélectionné.
     */
    mEmitChange () {
      this.$emit('rechChange', this.select);
    },

    /**
     * Permet de décaler la recherche de produit une fois que l'utilisateur à fini de saisir.
     */
    mGetDataDebounced () {
      clearTimeout(this._timerId);

      this._timerId = setTimeout(() => {
        this.mGetData();
      }, 500);
    },

    /**
     * Récupére les données pour l'autocompletion en fonction de la saisie utilisateur.
     */
    async mGetData () {
      this.isLoading = true;
      // Mutation du state loading.
      this.LOADING(true);

      let response = await searchSrv.getAutoCompleteData(this.champ, this.search, this.user.token);

      this.isLoading = false;
      this.LOADING(false);

      if (response.success) {
        this.items = response.data;
      }
      else if(response.warn) {
        this.$toast.warning(response.error);
      }
      else {
        this.$toast.error(response.error);
      }
    }
  },
};
</script>

<style>

</style>
