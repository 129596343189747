<template>
  <v-app>
    <app-header v-if="$router.currentRoute.name !== 'Non Autorisé'" />

    <v-main>
      <router-view />
    </v-main>

    <app-loader v-if="$router.currentRoute.name !== 'Non Autorisé'" />
  </v-app>
</template>

<script>
import AppLoader from '@/components/common/AppLoader';
import AppHeader from '@/components/common/AppHeader';

export default {
  name: 'App',

  components: {
    AppLoader,
    AppHeader
  },

  methods: {

  }
};
</script>
