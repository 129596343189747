import store from '@/store';

let appUrl;
let authKeycloak;

/**
 * @property {string} keycloakInfos.url
 * @property {string} keycloakInfos.realm
 * @property {string} keycloakInfos.clientId
 */
let keycloakInfos;

/**
 * Callback appelée une fois l'utilisateur correctement authentifié.
 * On peut alors aller initialiser les infos de l'utilisateur dans le store.
 */
let actionOnAuthSuccess = () => {
  let isLogin = store.getters['auth/isLogin'];

  if(authKeycloak && authKeycloak.tokenParsed && authKeycloak.token) {
    store.commit('auth/LOGIN', {
      login:  '',
      name:  '',
      email: authKeycloak.tokenParsed.preferred_username,
      token: authKeycloak.token
    });
  }

  if (!isLogin) {
    store.commit('data/setIsOKCommande', false);
  } else {
    // l'utilisateur est connecté => on met à jour le token du store.
    let user = store.getters['auth/user'];
    user.token = authKeycloak.token;
  }
};

/**
 * Callback appelée à l'expiration de l'access token.
 * On peut alors récupérer l'access token mis à jour pour modifier le store.
 */
let actionOnTokenExpired = () => {

  authKeycloak.updateToken(40)
  .then((refreshed) => {
    if (refreshed) {
      let user = store.getters['auth/user'];
      user.token = authKeycloak.token;
      user.email = authKeycloak.tokenParsed.preferred_username;
      store.commit('auth/LOGIN', user);
    } else {
      console.log('Token still valid');
    }
  })
  .catch((e) => {
    console.log('Failed to refresh token');
    console.log(e);
  });

};

/**
 * @returns {string}
 */
let getOrigin = ()  => {
  if (!window.location.origin) {
      return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  } else {
      return window.location.origin;
  }
};

export default {

  /**
   * @returns {Object}
   */
  getKeycloakInfos: () => {
    return keycloakInfos || {};
  },

  /**
   * Charge keycloak-js depuis le serveur Keycloak.
   */
  loadKeycloakJs: async () => {
    let loader = document.getElementById('loader');
    let err = document.getElementById('error');
    loader.style.display = 'block';

    try {
      if (!keycloakInfos) {
        // On récupère l'Url de keycloak.
        let res = await axios.get('keycloak');
        let data = res && res.data ? res.data.data: null;
        if (!data) {
          console.error('Problème de connexion au serveur. Merci de réessayer plus tard ou de contacter le support.');
          return;
        }

        keycloakInfos = data;
      }

        let event;
        if (typeof(Event) === 'function') {
            event = new Event('keycloakJsLoaded');
        } else {
            // Pour IE.
            event = document.createEvent('Event');
            event.initEvent('keycloakJsLoaded', true, true);
        }

        window.dispatchEvent(event);
        loader.style.display = 'none';
    } catch (error) {
      err.style.display = 'block';
      loader.style.display = 'none';

      console.error(error.message);
    }
  },

  /**
   * @returns {Promise}
   */
  login: (pKeycloak) => {
    authKeycloak = pKeycloak;
    authKeycloak.onAuthSuccess = actionOnAuthSuccess;
    authKeycloak.onTokenExpired = actionOnTokenExpired;

    return authKeycloak.init({
      // Enables logging messages from Keycloak to the console.
      // enableLogging: true,
      // 'login-required' will authenticate the client if the user is logged-in to Keycloak or display the login page if not.
      // 'check-sso' will only authenticate the client if the user is already logged-in, if the user is not logged-in the browser will be redirected back to the application and remain unauthenticated.
      onLoad: 'login-required',
      checkLoginIframe: false
    })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      }

      if (authKeycloak.token) {
        if (!appUrl) {
          appUrl = getOrigin();
        }

        return Promise.resolve();
      } else {
        store.commit('auth/LOGOUT');
      }

      return Promise.reject('Authentication failure : token absent.');
    })
    .catch((e) => {
      return Promise.reject('Erreur lors de la tentative d\'authentification.');
    });
  },

  logout: () => {
    authKeycloak.logout({
      redirectUri: appUrl
    });
  },
};
