<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <v-dialog v-model="active" max-width="600px" persistent>
    <v-card>
      <v-card-title class="headline">
        Attention !
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="2" md="2">
            <v-icon color="#fb8c00" large>
              fa-exclamation-triangle
            </v-icon>
          </v-col>
          <v-col sm="10" md="10">
            <span class="text"> {{ message }} </span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!isToConfirm"
          color="green darken-1"
          text
          @click="closeDialog()"
        >
          Ok
        </v-btn>
        <v-btn
          v-if="isToConfirm"
          color="green darken-1"
          text
          @click="confirmDialog(false)"
        >
          Annuler
        </v-btn>
        <v-btn
          v-if="isToConfirm"
          color="green darken-1"
          text
          @click="confirmDialog(true)"
        >
          Confirmer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
      required: true,
    },
    isToConfirm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("switchDialog", false);
    },
    confirmDialog(confirm) {
      this.$emit("confirmDialog", confirm);
    },
  },
};
</script>

<style>
.theme--light.v-card .v-card__text {
  color: "#fb8c00";
}
.v-dialog .v-card .v-card__text {
  padding: 0 24px 2px;
}
.icon-warn {
  display: inline;
  width: 10%;
  color: #ffc107 !important;
}
.text {
  display: inline;
  width: 90%;
  color: #ffc107 !important;
}
</style>
