<template>
  <v-card class="mx-auto" max-width="100%" outlined>
    <v-card-text>
      <v-data-table
        :headers="headersArray"
        :options.sync="pagination"
        :footer-props="footerProps"
        :server-items-length="totalItems"
        :items-per-page="rowsPerPage"
        :loading="loading"
        :items="itemsArray"
        :hide-default-header="true"
        no-data-text="Données non disponibles"
        loading-text="Chargement en cours, veuillez patienter..."
        class="elevation-1"
      >
        <template #header="{props: { headers }}">
          <thead>
            <tr class="header-up">
              <th
                v-for="header in headers"
                :key="header.text"
                scope="col"
                class="header-item"
                @click="sortBy(header)"
              >
                <span class="header-lebelle">{{ header.text }}</span>
                <v-icon v-if="header.sortable && header.sortDesc">
                  fas fa-arrow-up
                </v-icon>
                <v-icon v-if="header.sortable && !header.sortDesc">
                  fas fa-arrow-down
                </v-icon>
              </th>
            </tr>
            <tr>
              <th v-for="header in headers" :key="header.text" scope="col" class="input-item">
                <v-text-field
                  v-if="header.value && header.type === 'text'"
                  v-model="header.search"
                  :type="header.type"
                  append-icon="fas fa-search"
                  @input="resetSearch(header)"
                  @click:append="searchBy()"
                  @keyup.enter="searchBy()"
                />
                <app-date
                  v-if="header.value === 'date_creation'"
                  v-model="header.search"
                  :initial-date="header.search"
                  label=""
                  @dateChange="searchByCreationDate"
                />
                <app-date
                  v-if="header.value === 'date_envoi'"
                  v-model="header.search"
                  :initial-date="header.search"
                  label=""
                  @dateChange="searchBySendDate"
                />
                <v-select
                  v-if="header.value && header.type === 'select'"
                  v-model="header.search"
                  :items="header.items"
                  :item-text="item => item.text"
                  :item-value="item => item.value"
                  no-data-text="données non disponibles"
                  @change="searchBySelection()"
                />
              </th>
            </tr>
          </thead>
        </template>
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="text-center">
                <v-icon
                  color="blue"
                  small
                  title="Voir le détails de la commande"
                  @click="goToCommande(item.id)"
                >
                  fas fa-eye
                </v-icon>
              </td>
              <td>{{ item.numero }}</td>
              <td>{{ getShownDate(item.date_creation) }}</td>
              <td>{{ getShownDate(item.date_envoi) }}</td>
              <td>{{ item.reference }}</td>
              <td>{{ item.commentaire }}</td>
              <td>{{ item.contrat_code }}</td>
              <td>{{ item.nb_echantillons }}</td>
              <td>{{ getLibelleEtat(item.etat) }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import AppDate from "@/components/common/AppDate";
import mixins from '@/mixins/mixins.js';
import searchSrv from "@/services/searchService";

export default {
  components: {
    AppDate
  },
  mixins: [mixins],
  props: {
    data: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    totalCommandes: {
      type: Number,
      required: false,
      default() {
        return 0;
      }
    },
    contrats: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    reset: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    searchField: {
      type: String,
      required: false,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      headersArray: [
        {
          text: "Actions",
          align: "left",
          sortable: false,
          value: null,
          type: "",
          width: "5%",
          sortDesc: false,
          search: ""
        },
        {
          text: "N° commande",
          align: "left",
          sortable: true,
          value: "numero",
          type: "text",
          width: "10%",
          sortDesc: true,
          search: ""
        },
        {
          text: "Date création",
          align: "left",
          sortable: true,
          value: "date_creation",
          type: "date",
          width: "10%",
          sortDesc: true,
          search: ""
        },
        {
          text: "Date d'envoi",
          align: "left",
          sortable: true,
          value: "date_envoi",
          type: "date",
          width: "10%",
          sortDesc: true,
          search: ""
        },
        {
          text: "Référence",
          align: "left",
          sortable: true,
          value: "reference",
          type: "text",
          width: "10%",
          sortDesc: true,
          search: ""
        },
        {
          text: "Contact",
          align: "left",
          sortable: true,
          value: "commentaire",
          type: "text",
          width: "15%",
          sortDesc: true,
          search: ""
        },
        {
          text: "Contrat",
          align: "left",
          sortable: true,
          value: "contrat_code",
          type: "select",
          items: this.contrats,
          width: "10%",
          sortDesc: true,
          search: ""
        },
        {
          text: "Nbr d'échantillons",
          align: "left",
          sortable: true,
          value: "nb_echantillons",
          type: "text",
          width: "10%",
          sortDesc: true,
          search: ""
        },
        {
          text: "Etat",
          align: "left",
          sortable: true,
          value: "etat",
          type: "select",
          items: [],
          width: "10%",
          sortDesc: true,
          search: ""
        }
      ],
      footerProps: {
        itemsPerPageAllText: "100",
        itemsPerPageOptions: [5, 10, 20, 100],
        itemsPerPageText: "Commandes par page :",
        showCurrentPage: true,
        showFirstLastPage: true
      },
      offset: 0,
      loading: false,
      pagination: {
        page: 1
      },
      rowsPerPage: 5,
      totalItems: 0,
      commandes: [],
      itemsArray: [],
      sort: "",
      sortByField: "adherent_id",
      search: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["isLogin", "user"]),
    ...mapGetters("data", ["listEtats"])
  },
  watch: {
    pagination: {
      handler() {
        this.getCommandes();
      },
      deep: true
    },
    reset(newVal, oldVal) {
      if(newVal) {
        this.resetAllFields();
        this.$emit('resetsearch');
      }
    },
    contrats(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.initItems(newVal, "contrat_code");
      }
    },
    data(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.itemsArray = newVal;
      }
    },
    totalCommandes(newVal, oldVal) {
      this.totalItems = newVal;
    }
  },
  created() {
    this.getEtats();
    this.commandes = this.data ? this.data : [];
  },
  methods: {
    ...mapMutations("loading", ["LOADING"]),
    ...mapMutations("data", ["setListEtats"]),

    initItems(data, item) {
      for (const header of this.headersArray) {
        if (header.value === item) {
          header.items = data;
        }
      }
    },
    searchBySelection() {
      this.searchBy();
    },
    resetAllFields() {
      for (const header of this.headersArray) {
        header.search = null;
      }
      this.searchBy();
    },
    resetSearch(item) {
      if (!item.search) {
        this.searchBy();
      }
    },
    setDateField(date, field) {
      let isOK = false;
      for (const header of this.headersArray) {
        if (header.value === field && header.search !== date) {
          header.search = date;
          isOK = true;
        }
      }
      return isOK;
    },
    searchByCreationDate(date) {
      if(date){
        let isOK = this.setDateField(date, 'date_creation');
        if(isOK) {
          this.searchBy();
        }
      }
    },
    searchBySendDate(date) {
      if(date){
        let isOK = this.setDateField(date, 'date_envoi');
        if(isOK) {
          this.searchBy();
        }
      }
    },
    goToCommande(id) {
      this.$router.push({ path: `/commande/${id}` });
    },
    getLibelleEtat(code) {
      let libelle = '';
      if (this.listEtats && this.listEtats.length > 0) {
        for(let i = 0; i < this.listEtats.length; i++) {
          if(this.listEtats[i].value === code) {
            libelle = this.listEtats[i].text;
            i = this.listEtats.length;
          }
        }
      }
      return libelle;
    },
    async searchBy() {
      this.search = "";
      for (let item of this.headersArray) {
        if (item.search) {
          let format1 = item.value + ":lk:" + item.search + "%,";
          let format2 = item.value + ":eq:" + item.search + ",";
          this.search += item.value === 'nb_echantillons' || item.value === 'etat' ? format2 : format1;
        }
      }
      this.search = this.search.slice(0, -1);
      await this.getCommandes();
    },
    async sortBy(item) {
      this.sortByField = item.value;
      let sortDesc = item.sortDesc ? "" : "-";
      this.sort = this.sortByField ? sortDesc + this.sortByField : "";
      await this.getCommandes(true);
      item.sortDesc = !item.sortDesc;
    },
    async getCommandes() {
      this.LOADING(true);

      this.totalItems = 0;

      this.rowsPerPage = this.pagination.itemsPerPage
        ? this.pagination.itemsPerPage
        : this.rowsPerPage;

      let offset = (this.pagination.page - 1) * this.rowsPerPage;

      let response;
      if(this.searchField) {
        response = await searchSrv.getAllCommandes(this.user.token, this.searchField);
      }
      else {
        response = await searchSrv.getCommandes(
          this.user.token,
          this.rowsPerPage,
          offset,
          this.sort,
          this.search
        );
      }

      this.LOADING(false);

      if (response.success) {
        if (!response.commandes || response.commandes.length === 0) {
          this.$toast.warning(response.msg);
        }
        this.itemsArray = this.getCommandesWithContratsLibele(response.commandes, this.contrats);
        this.totalItems = response.nbCommandes;
      } else {
        this.$toast.error(response.error);
      }
    },
    async getEtats() {
      if (this.listEtats && this.listEtats.length > 0) {
        this.initItems(this.listEtats, "etat");
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getEtats(this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.etats = this.getMappedItems(response.etats);
        this.initItems(this.etats, "etat");
        this.setListEtats(this.etats);
      } else {
        this.$toast.error(response.error);
      }
    }
  }
};
</script>

<style>
  .header-up th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .theme--light.v-data-table thead tr:last-child th {
    background-color: white;
  }
  .header-item .v-icon.v-icon {
    font-size: 12px;
  }
  .input-item .v-icon.v-icon {
    font-size: 12px;
  }
  .input-item .v-label {
    font-size: 10px;
  }
  .header-item *:hover {
    color: black;
    font-size: 13px;
  }
</style>
