<template>
  <transition name="fade">
    <div
      v-if="loading"
      id="mask"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
      <p>Chargement en cours, veuillez patienter...</p>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('loading', ['loading'])
  },
};
</script>

<style scoped>
#mask{
  background: rgba(0,0,0,0.5);
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#mask p{
  font-size: 30px;
  color: white;
  margin: -150px 0 0 0;
  font-weight: 400;
  pointer-events: none;
}
</style>

