<template>
  <v-container fluid grid-list-md>
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-row class="header-menu">
        <v-col sm="12" md="5">
          <v-text-field
            v-model="searchedText"
            type="text"
            append-icon="fas fa-search"
            label="Chercher les commandes"
            class="search-input"
            clearable
            @click:clear="resetAllFileds()"
            @click:append="getAllCommandes()"
            @keyup.enter="getAllCommandes()"
          />
        </v-col>
        <v-spacer />
        <v-col sm="12" md="7">
          <v-spacer />
          <v-btn color="secondary" class="reset-btn" @click="resetAllFileds()">
            <v-icon left>
              fas fa-redo
            </v-icon>
            Réinitialiser
          </v-btn>
          <v-btn color="primary" class="add-commande-btn" @click="goTo('commande')">
            <v-icon left>
              fas fa-plus-circle
            </v-icon>
            Ajouter une commande
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <list-commandes
      :reset="reset"
      :contrats="contrats"
      :data="commandes"
      :total-commandes="totalCommandes"
      :search-field="searchField"
      @resetsearch="resetsearch"
    />
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import listCommandes from "@/components/commandes/listCommandes";
import mixins from '@/mixins/mixins.js';
import searchSrv from "@/services/searchService";
import moment from "moment";

export default {
  components: {
    listCommandes
  },
  mixins: [mixins],
  data() {
    return {
      reset: false,
      searchField: '',
      contrats: [],
      contratsActifs: [],
      commandes: null,
      totalCommandes : -1,
      searchedText: "",
      required: [v => !!v || "Champ requis"]
    };
  },

  computed: {
    ...mapGetters("auth", ["isLogin", "user"]),
    ...mapGetters("data", ["listAllContrats", "listContratsActifs", "lastEchVisited", "listCommandes"])
  },

  watch: {},

  created() {
    this.getAllContrats();
    this.getContratsActifs();
  },

  methods: {
    // Mapping des mutations du store.
    ...mapMutations("loading", ["LOADING"]),
    ...mapMutations("data", ["setListCommandes", "setListAllContrats", "setListContratsActifs", "setListEtats"]),

    resetsearch() {
      this.reset = false;
    },
    /**
     * Vide les champs du formulaire de recherche.
     */
    resetAllFileds() {
      this.searchedText = "";
      this.reset = true;
      this.searchField = "";
    },

    goTo(page) {
      this.$router.push("/" + page + "/");
    },

    getCodeContrat(search) {
      let code = "";
      for (let contrat of this.listAllContrats) {
        if (contrat.text.toUpperCase() === search.toUpperCase()) {
          code = contrat.value;
        }
      }
      return code;
    },

    async getAllContrats() {
      if (this.listAllContrats && this.listAllContrats.length > 0) {
        this.contrats = this.listAllContrats;
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getContrats(this.user.token, true);
      this.LOADING(false);

      if (response.success) {
        this.contrats = this.getMappedContrats(response.contrats);
        this.setListAllContrats(this.contrats);
      } else {
        this.$toast.error(response.error);
      }
    },

    async getContratsActifs() {
      if (this.listContratsActifs && this.listContratsActifs.length > 0) {
        this.contratsActifs = this.listContratsActifs;
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getContrats(this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.contratsActifs = this.getMappedContrats(response.contrats);
        this.setListContratsActifs(this.contratsActifs);
      } else {
        this.$toast.error(response.error);
      }
    },

    async getAllCommandes() {
      if(!this.searchedText) {
        return;
      }
      this.searchedText = this.searchedText.replace(/ /g, '');
      let d = moment(this.searchedText, 'DD/MM/YYYY');
      let dd = moment(this.searchedText, 'DD-MM-YYYY');
      let date = '';
      if(d.isValid()) {
        date = moment(d).format('YYYY-MM-DD');
      }
      else if(dd.isValid()) {
        date = moment(dd).format('YYYY-MM-DD');
      }
      let code = this.getCodeContrat(this.searchedText);
      this.searchField = date ? date : code ? code : this.searchedText;
      this.LOADING(true);
      let response = await searchSrv.getAllCommandes(this.user.token, this.searchField);
      this.LOADING(false);

      if (response.success) {
        this.commandes = this.getCommandesWithContratsLibele(response.commandes, this.contrats);
        this.totalCommandes = response.nbCommandes;
      } else {
        this.$toast.error(response.error);
      }
    }
  }
};
</script>

<style scoped>
.header-menu {
  margin-left: 5px;
  padding: 10px;
}
.add-commande-btn {
  float: right;
  margin-right: 10px;
}
.reset-btn {
  float: right;
  margin-right: 5px;
}
.space {
  margin-right: 20px;
}
.space-btn {
  margin-right: 10px;
}
</style>
