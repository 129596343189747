import lockr from 'lockr';

export default {
  namespaced: true,
  state: {
    isOKCommande: lockr.get('isokcommande') || false,
    currentCommande: {
      id: ''
    },
    listTiers: [],
    listCommandes: [],
    listAllContrats: [],
    listContratsActifs: [],
    lastEchVisited: null,
    listAnalyses: [],
    listFamille: [],
    listUnites: [],
    listInfos: {},
    listEtats: [],
    listLmrs: [],
  },

  getters: {
    isOKCommande: state => state.isOKCommande,
    currentCommande: state => state.currentCommande,
    listTiers: state => state.listTiers,
    listCommandes: state => state.listCommandes,
    listAllContrats: state => state.listAllContrats,
    listContratsActifs: state => state.listContratsActifs,
    lastEchVisited: state => state.lastEchVisited,
    listAnalyses: state => state.listAnalyses,
    listFamille: state => state.listFamille,
    listUnites: state => state.listUnites,
    listInfos: state => state.listInfos,
    listEtats: state => state.listEtats,
    listLmrs: state => state.listLmrs,
  },

  // Setters.
  mutations: {
    /**
     * Mise à jour de la isOKCommande
     * @param value
     */
    setIsOKCommande: (state, value) => {
      lockr.set('isokcommande', value);
      state.isOKCommande = value;
    },
    /**
     * Mise à jour de la commande
     * @param value
     */
    setCurrentCommande: (state, value) => {
      state.currentCommande = value;
    },
    /**
     * Mise à jour de la liste des commandes
     * @param value
     */
    setListTiers: (state, value) => {
      state.listTiers = value;
    },
    /**
     * Mise à jour de la liste des commandes
     * @param value
     */
    setListCommandes: (state, value) => {
      state.listCommandes = value;
    },
    /**
     * Mise à jour de la liste des contrats
     * @param value
     */
    setListAllContrats: (state, value) => {
      state.listAllContrats = value;
    },
    /**
     * Mise à jour de la liste des contrats
     * @param value
     */
    setListContratsActifs: (state, value) => {
      state.listContratsActifs = value;
    },
    /**
     * Mise à jour de la liste des analyses
     * @param value
     */
    setListAnalyses: (state, value) => {
      state.listAnalyses = value;
    },
    /**
     * Mise à jour de la liste des famille
     * @param value
     */
    setListFamille: (state, value) => {
      state.listFamille = value;
    },
    /**
     * Mise à jour de la liste des unites
     * @param value
     */
    setListUnites: (state, value) => {
      state.listUnites = value;
    },
    /**
     * Mise à jour de la liste des unites
     * @param value
     */
    setListInfos: (state, value) => {
      state.listInfos = value;
    },
    /**
     * Mise à jour de la liste des lmrs
     * @param value
     */
    setListLmrs: (state, value) => {
      state.listLmrs = value;
    },
    /**
     * Mise à jour de la liste des etats
     * @param value
     */
    setListEtats: (state, value) => {
      state.listEtats = value;
    },
    /**
     * Mise à jour
     * @param value
     */
    setLastEchVisited: (state, value) => {
      state.lastEchVisited = value;
    },
  },

  /**
   * Toute action asynchrone doit être dans actions
   */
  actions: {

  }
};
