import Vue from 'vue';
import conf from '@/config/config';
import Keycloak from 'keycloak-js';
Vue.conf = conf;

import './plugins/axios';
import vuetify from './plugins/vuetify';
import toast from '@/plugins/toast';
import App from './App.vue';
import router from './router';
import store from './store';

import lockr from 'lockr';
window.lockr = lockr;

Vue.use(toast, {});

Vue.config.productionTip = false;

import keycloakSrv from '@/services/keycloakService';
keycloakSrv.loadKeycloakJs();

// Une fois l'événement informant que le script keycloak est chargé on peut lancer la création de Vue.
window.addEventListener('keycloakJsLoaded', () => {
  let keycloakOptions = {...keycloakSrv.getKeycloakInfos()};
  let keycloak = new Keycloak(keycloakOptions);

  keycloakSrv.login(keycloak)
  .then(() => {

    /**
     * Injection des dépendances
     * "router" : donne accès à this.$router et this.$route dans chaque composant
     */
    window.Vue = new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  })
  .catch((errorMessage) => {
    console.error(errorMessage);
  });
});
