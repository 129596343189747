<template>
  <v-container grid-list-md>
    <v-card>
      <v-card-title class="headline lighten-3">
        Sélection de tiers
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="8"
            lg="9"
            sm="12"
            xs="12"
          >
            <v-autocomplete
              v-model="codeTiers"
              :items="tiers"
              :loading="isLoading"
              :filter="mTiersFilter"
              class="list-tiers"
              color="white"
              item-text="TIERS_NOM_PRENOM_CODE_SOCIETE"
              item-value="TIERS_CODE"
              placeholder="Commencez à taper pour faire une recherche"
              prepend-icon="mdi-database-search"
              no-data-text="Pas de tiers trouvés"
              dense
              solo
            >
              <template #selection="{ item }">
                <v-chip
                  class="ma-2 ps-6"
                  small
                >
                  <v-icon left small>
                    fas fa-user
                  </v-icon>
                  {{ item.TIERS_NOM }} - {{ item.TIERS_PRENOM }} ({{ item.TIERS_CODE }} {{ item.TIERS_SOCIETE }})
                </v-chip>
              </template>
              <template #item="{ item }">
                <v-list-item-icon>
                  <v-icon>
                    fas fa-user
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ item.TIERS_NOM }}</span><span v-if="item.TIERS_PRENOM"> - </span><span>{{ item.TIERS_PRENOM }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span>{{ item.TIERS_CODE }}</span><span v-if="item.TIERS_SOCIETE"> - </span><span>{{ item.TIERS_SOCIETE }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
            sm="12"
            xs="12"
          >
            <v-btn class="goto" @click="mGetTiersInfo()">
              <v-icon left>
                fas fa-search
              </v-icon>
              <span>charger les données</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import searchSrv from '@/services/searchService';

export default {
  name: 'Home',
  data() {
    return {
      tiers: [],
      isLoading: false,
      codeTiers: null,
      search: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['isLogin', 'user']),
    ...mapGetters('data', ['listTiers']),
  },

  watch: {
    search(val) {
      if (this.items.length > 0) return;
      if (this.isLoading) return;
      this.isLoading = true;
    },
  },

  created() {
    this.mGetTiers();
  },

  methods: {
    // Map l'action "login" du store vers "this.store_login".
    ...mapActions('auth', { store_login: 'login' }),
    ...mapMutations('loading', ['LOADING']),
    ...mapMutations('data', ['setListTiers', 'setIsOKCommande']),

    async mGetTiersInfo() {
      if (!this.codeTiers) {
        this.$toast.warning('Veuillez sélectionner un tiers.');
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getTiersInfo(
        this.codeTiers,
        this.user.token
      );
      this.LOADING(false);

      if (response.success && response.tiers) {
        this.store_login({
          login: response.tiers.login,
          name: response.tiers.name,
          firstName: response.tiers.firstName,
          phone: response.tiers.phone,
          token: this.user.token,
          email: this.user.email,
        });
        this.$router.push('/echantillons');
        this.mGetTestAccess();
      } else {
        this.$toast.error(response.error);
      }
    },

    async mGetTestAccess() {
      this.LOADING(true);
      let res = await searchSrv.getTestAccess(this.user.token);
      this.LOADING(false);

      if(res.success) {
        this.setIsOKCommande(res.isOK);
      }
    },

    async mGetTiers() {
      if (this.listTiers && this.listTiers.length > 0) {
        this.tiers = this.listTiers;
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getTiersByEmail(
        this.user.token,
        this.user.email
      );
      this.LOADING(false);

      if (response.success) {
        this.tiers = response.tiers;
        this.setListTiers(this.tiers);
        // S'il n'y a qu'un seul tiers, on charge directement les données.
        if (this.tiers.length === 1) {
          this.codeTiers = this.tiers[0].TIERS_CODE;
          this.mGetTiersInfo();
          response.msg += ' Chargement des données.';
        }
        if (response.msg) {
          this.$toast.success(response.msg);
        }
      } else {
        this.$toast.error(response.error);
      }
    },

    mTiersFilter(item, queryText, itemText) {
      var itemText = '';
      if (item.TIERS_NOM_PRENOM_CODE_SOCIETE) {
        itemText = item.TIERS_NOM_PRENOM_CODE_SOCIETE.toLowerCase();
      }
      var searchText = '';
      if (queryText) {
        searchText = queryText.toLowerCase().replaceAll('  ', ' ');
      }
      var searchTexts = searchText.split(' ');
      var found = true;
      var foundAt = -1;
      for(var i=0;i<searchTexts.length;i++) {
        searchText = searchTexts[i];
        foundAt = itemText.indexOf(searchText);
        found = found && foundAt > -1;
      }
      return found;
    },
  },
};
</script>

<style scoped>
@media (min-width: 1496px) {
  .container {
    max-width: 1490px;
  }
}
.goto {
  height: 38px !important;
}
</style>
