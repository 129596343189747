import moment from "moment";

export default {
   methods: {
      getShownDate(date) {
         if(date) {
            return moment(date).format("L");
         }
         return '';
      },
      getMappedContrats: function(data) {
         let contrats = [];
         for (let contrat of data) {
            contrats.push({
               text: `${contrat.CONTRAT_LIBELLE} ( ${contrat.CONTRAT_CODE} )`,
               value: contrat.CONTRAT_CODE
            });
         }
         return contrats;
      },
      getMappedItems: function(data, type) {
         let items = [];
         let id = 'id', libelle = 'libelle';
         if(type === 'sousfamiles') {
            id = 'sousfamille_id';
         }
         for (let item of data) {
            items.push({
               text: item[libelle],
               value: item[id]
            });
         }
         return items;
      },
      getLibelleContrat(code, contrats) {
         let libelle = "";
         for (let contrat of contrats) {
            if (contrat.value === code) {
               libelle = contrat.text;
            }
         }
         return libelle;
      },
      getCommandesWithContratsLibele(commandes, contrats) {
         let cmds = commandes;
         for(let i = 0; i < cmds.length; i++) {
            cmds[i].contrat_code = this.getLibelleContrat(cmds[i].contrat_code, contrats);
         }
         return cmds;
      },
      getFormatedAnalyses(listAnalyses) {
         let anl =  [];
         for(let analyse of listAnalyses) {
            anl.push({
               text: analyse.libelle ? analyse.libelle : '',
               value: analyse.code ? analyse.code : '',
               prix: analyse.prix ? parseFloat(analyse.prix).toFixed(2) + ' €' : '0 €'
            });
         }
         return anl;
      },
      setEmptyFields(ech) {
         let keys = Object.keys(ech);
         for(let key of keys) {
            if(ech[key] === null) {
               ech[key] = '';
            }
         }
         return ech;
      },
      toPdf(data, filename) {
         var link = document.createElement('a');
         link.innerHTML = "Télecharger l'étiquette chronopost";
         link.download = filename+'.pdf';
         link.href = 'data:application/octet-stream;base64,' + data;
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      }
   },
};