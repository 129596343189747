<template>
  <v-data-table
    :headers="headers"
    :items="echantillons"
    :footer-props="footerProps"
    :items-per-page="5"
    no-data-text="Données non disponibles"
    class="elevation-1"
  >
    <template #body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.echantillon">
          <td class="text-left">
            <v-icon
              color="blue"
              size="32"
              title="Voir le détails de l'échantillon"
              @click="goToEchantillon(item.id)"
            >
              fas fa-eye
            </v-icon>
          </td>
          <td>{{ item.numero }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.analyses }}</td>
          <td>{{ item.prix_ht }} €</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    cid: {
      type: String,
      required: false,
      default: ''
    },
    analyses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Actions",
          align: "left",
          sortable: false,
          value: null,
          width: "10%"
        },
        {
          text: "Echantillon",
          align: "left",
          sortable: true,
          value: "numero",
          width: "10%"
        },
        {
          text: "Type d'échantillon",
          align: "left",
          sortable: true,
          value: "type",
          width: "20%"
        },
        {
          text: "Liste des analyses",
          align: "left",
          sortable: true,
          value: "analyses",
          width: "50%"
        },
        {
          text: "Analyse HT",
          align: "left",
          sortable: true,
          value: "prix_ht",
          width: "10%"
        }
      ],
      footerProps: {
        itemsPerPageAllText: "Tous",
        itemsPerPageOptions: [5, 10, 20, -1],
        itemsPerPageText: "Echantillons par page :",
        showCurrentPage: true,
        showFirstLastPage: true
      },
      echantillons: []
    };
  },
  computed: {
    ...mapGetters("data", ["listFamille"])
  },
  watch: {
    analyses(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.echantillons = [];
        this.getEchantillons();
      }
    }
  },
  created() {
    this.getEchantillons();
  },
  methods: {
    getPrixAnalyse(prix) {
      if(prix) {
        return parseFloat(prix.replace(" €", '')).toFixed(2);
      }
      return 0;
    },
    getInfoAnalyses(codeAnalyse) {
      let info = {
        libelle: '',
        prix: 0
      };
      if(codeAnalyse && this.analyses) {
        for(let i = 0; i < this.analyses.length; i++) {
          if(codeAnalyse === this.analyses[i].value) {
            info.libelle = this.analyses[i].text;
            info.prix = this.getPrixAnalyse(this.analyses[i].prix);
            i = this.analyses.length;
          }
        }
      }
      return info;
    },
    getAnalyses(analyses) {
      let anl = {
        listAly: "",
        prix: 0
      }; 
      if(analyses && analyses.length > 0) {
        for (let ana of analyses) {
          let anaInfo = this.getInfoAnalyses(ana);
          anl.listAly += anaInfo.libelle + ", ";
          anl.prix += parseFloat(anaInfo.prix);
        }
        anl.listAly = anl.listAly.slice(0, -2);
        return anl;
      }
      return anl;
    },
    getType(familleCode) {
      let type = "";
      for(let item of this.listFamille) {
        if(item.value === familleCode) {
          type = item.text;
        }
      }
      return type;
    },
    getEchantillons() {
      if (this.data) {
        for (let ech of this.data) {
          let analyses = this.getAnalyses(ech.analyses);
          let type = this.getType(ech.famille);
          this.echantillons.push({
            id: ech.numero,
            numero: ech.numero,
            type: type,
            analyses: analyses.listAly,
            prix_ht: parseFloat(analyses.prix).toFixed(2)
          });
        }
      }
    },
    goToEchantillon(id) {
      if(this.cid && id) {
        this.$router.push("/echantillon/" + this.cid + "/" + id);
      }
    }
  }
};
</script>

<style scoped>
</style>
