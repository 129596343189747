<template>
  <div
    v-if="echantillons.length > 0 && !isError"
    class="mt-4"
  >
    <v-data-table
      :footer-props="footerProps"
      :headers="cHeadersList"
      :items="echantillons"
      :obj-recherche="objRecherche"
      item-key="id"
      class="elevation-1"
      dense
      fixed-header
      no-data-text="Pas de résultats."
      height="500px"
    >
      <template #body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.echantillon">
            <td class="text-center">
              <v-icon
                v-if="item.rapport"
                color="red"
                class="doc"
                small
                title="Télécharger le rapport"
                @click="mGetUrlPdf(item, 'rapport', item.rapport)"
              >
                fas fa-file-pdf
              </v-icon>
              <v-icon
                v-if="item.annexe"
                color="red"
                class="doc"
                small
                title="Télécharger l'annexe"
                @click="mGetUrlPdf(item, 'annexe', item.annexe)"
              >
                fas fa-file-pdf
              </v-icon>
              <v-icon
                v-if="item['accuse_reception']"
                color="red"
                small
                title="Télécharger l'accusé de réception"
                @click="mGetUrlPdf(item, 'accuse-reception', item['accuse_reception'])"
              >
                fas fa-file-pdf
              </v-icon>
            </td>
            <td>{{ item.echantillon }}</td>
            <td>{{ mDateFr(item.reception) }}</td>
            <td>{{ mDateFr(item.diffusion) }}</td>
            <td>{{ item.identification_1 }}</td>
            <td>{{ item.identification_2 }}</td>
            <td>{{ item.donneur_ordre }}</td>
            <td>{{ item.proprietaire }}</td>
            <td>{{ item.payeur }}</td>
            <td>{{ item.etat }}</td>
            <td>{{ item.ref_commande }}</td>
            <td>{{ item.info_reception }}</td>
            <td v-for="(i, index) in cNbAnalyses" :key="index">
              {{ mAnalyse(i, item) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/fr';
import { mapMutations, mapGetters } from 'vuex';
import searchSrv from '@/services/searchService';

export default {
  name: 'ListEchantillons',
  props: {
    headerEchantillons: {
      type: Object,
      required: true
    },
    echantillons: {
      type: Array,
      default: () => []
    },
    isTooBig: {
      type: Boolean,
      default: false
    },
    objRecherche: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isError: false,
      footerProps: {
        itemsPerPageAllText: 'Tous',
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: 'Résultats par page :',
        showCurrentPage: true,
        showFirstLastPage: true
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['user', 'isLogin']),
    /**
     * Génére le tableau pour les headers de v-data-table.
     * @return {Array.<Object>}
     */
    cHeadersList () {
      if (!this.echantillons || !this.echantillons[0]) {
        this.$toast.error('Une erreur est survenue');
        return;
      }

      // Les entêtes par défaut.
      let headers = [
        { text: 'Export', value: 'export', sortable: false, width: '1%' },
        { text: 'Echantillon', value: 'echantillon', width: '5%' },
        { text: 'Date réception', value: 'reception', width: '5%' },
        { text: 'Date diffusion', value: 'diffusion', width: '5%' },
        { text: 'Id. 1', value: 'identification_1', width: '15%' },
        { text: 'Id. 2', value: 'identification_2', width: '15%' },
        { text: 'Donneur d\'ordre', value: 'donneur_ordre', width: '5%' },
        { text: 'Propriétaire', value: 'proprietaire', width: '5%' },
        { text: 'Payeur', value: 'payeur', width: '5%' },
        { text: 'Etat', value: 'etat', width: '3%' },
        { text: 'Réf. commande', value: 'ref_commande', width: '5%' },
        { text: 'Info. Reception', value: 'info_reception', width: '3%' }
      ];

      // Traitement pour rajouter les entêtes des analyses des échantillons.
      let headersAnalyses = [];

      for (let i = 1 ; i < this.cNbAnalyses + 1 ; i++) {
        let tAnalyse = this.headerEchantillons[`analyse_${i}`];
        let titreAnalyse = tAnalyse ? `${tAnalyse.libelle} (${tAnalyse.analyse}) unité : ${tAnalyse.unite}` : '';

        // Pour une analyse, on rajoute l'objet de type header au tableau.
        headersAnalyses.push(
          { text: titreAnalyse, value: `analyse_${i}`}
        );
      }
      return headers.concat(headersAnalyses);
    },

    /**
     * Retourne le nombre d'analyse dans le 1er échantillon : nombre de clés - les 12 champs fixes.
     * @return {number}
     */
    cNbAnalyses () {
      let res = 0;

      if (this.echantillons.length > 0) {
        res = Object.keys(this.echantillons[0]).length - 12;
      }

      return res;
    }
  },

  methods: {
    // Mapping des mutations du store.
    ...mapMutations('loading', ['LOADING']),

    /**
     * Retourne la valeur de l'analyse pI situé dans pItem.
     * @param {number} pI Le numéro de l'analyse.
     * @param {Object} pItem L'échantillon à lire.
     * @return {string} La valeur de l'analyse.
     */
    mAnalyse(pI = 0, pItem = {}) {
      const tAnalyse = pItem[`analyse_${pI}`];
      return tAnalyse ? tAnalyse.valeur: '';
    },

    /**
     * Retourne une date au format DD/MM/YYYY.
     */
    mDateFr(pDate) {
      if (!pDate) {
        return;
      }

      return moment(pDate).format('L');
    },

    /**
     * Renvoie l'url pour récupérer le pdf d'un échantillon.
     * @param {Object} pItem L'échantillon à lire.
     * @param typeDoc
     * @param fileName
     * @return {string} L'url pour récupérer le pdf de l'échantillon.
     */
    async mGetUrlPdf (pItem = {}, typeDoc, fileName) {
      if (!pItem.echantillon) {
        this.$toast.error('Numéro d\'échantillon manquant.');
        return;
      }

      // Mutation du state loading.
      this.LOADING(true);

      let response = await searchSrv.getPdf(pItem.echantillon, typeDoc, this.user.token);

      if (response.success) {

        // Formattage et création du Blob depuis le flux pdf.
        const file = new Blob (
          [response.data],
          { type: 'application/pdf' }
        );

        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
          window.navigator.msSaveOrOpenBlob(file, fileName);
        }
        else {   // for Non-IE (chrome, firefox etc.)
          let url = URL.createObjectURL(file);

          var downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = fileName;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
      else {
        this.$toast.error(response.error);
      }

      this.LOADING(false);
    }
  },
};
</script>

<style>
  .doc {
    margin-right: 5px;
  }
  .v-data-footer {
    margin-bottom: 50px;
  }
  .theme--light.v-data-table thead tr:last-child th {
    color: black;
    font-size: 12px;
    line-height: 1.2;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 12px !important;
  }
</style>
