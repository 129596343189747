import store from '@/store';

export default {
  /***************************** Tiers **********************************/
  getTiersByEmail: async (token, email) => {
    let response = {
      success: false,
      tiers: [],
      msg: '',
      error: ''
    };
    if (email && token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        let res = await axios.get(`tiers?email=`+email, { 'headers': headers });

        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.tiers = data;
          for(var i=0;i<response.tiers.length;i++) {
            response.tiers[i].TIERS_NOM_PRENOM_CODE_SOCIETE = [
              response.tiers[i].TIERS_NOM || '',
              response.tiers[i].TIERS_PRENOM || '',
              response.tiers[i].TIERS_CODE || '',
              response.tiers[i].TIERS_SOCIETE || ''
            ].join(' ').replaceAll('  ', ' ');
          }
          if (!res.error) {
            response.msg = `${response.tiers.length} tiers trouvé(s).`;
          }
        }
        else {
          response.success = true;
          response.msg = 'Pas de tiers correspondants.';
        }
        return response;
      } catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getTiersInfo: async (codeTiers, token) => {
    let response = {
      success: false,
      tiers: [],
      msg: '',
      error: ''
    };
    if (codeTiers && token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        let res = await axios.get('tiers-info?tiers-code=' + codeTiers, { headers });
        let data = res && res.data && res.data.data ? res.data.data.tiers : null;
        if (data) {
          response.success = true;
          response.tiers = data;
        }
        else {
          response.success = true;
          response.msg = 'Pas de résultats correspondants.';
        }
        return response;
      } catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  /***************************** Echantillons ***************************/
  getEchantillons: async (params, token) => {
    let response = {
      success: false,
      warn: false,
      isTooBig: false,
      echantillons: [],
      analyses: [],
      msg: 'Une erreur est survenue !'
    };
    if (token) {
      let headers = {
        'Authorization': 'Bearer ' + token
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.post('echantillons', params, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        let meta = res && res.data ? res.data.meta : null;

        if (data && data.echantillons) {
          response.success = true;
          response.echantillons = data.echantillons;
          response.analyses = data.analyses;

          if (meta && meta.error_code === 12) {
            response.warn = true;
            response.msg = 'Attention recherche trop longue, réessayer en affinant votre recherche';
          }
          else if (meta && meta.error_code === 5) {
            response.isTooBig = true;
            response.msg = '';
          }
          else {
            response.msg = `${data.echantillons.length} résultat(s) trouvé(s).`;
          }
        }
        else {
          response.warn = true;
          response.msg = "Pas de résultats.";
        }
        return response;
      } catch (error) {
        if (error.message.indexOf('timeout') !== -1) {
          response.warn = true;
          response.msg = 'Attention recherche trop longue, réessayer en affinant votre recherche !';
        }
        else {
          response.msg = 'Une erreur est survenue !';
        }

        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getExportedEchantillons: async (byResult, typeDoc, params, token) => {
    let response = {
      success: false,
      data: [],
      error: ''
    };
    if (token && params && typeDoc) {
      let url = 'echantillons/export';

      if (byResult) {
        url += '/par-resultat';
      } else {
        url += '/par-echantillon';
      }

      url += `?output=${typeDoc}`;

      let headers = {
        'Authorization': 'Bearer ' + token
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.post(url, params, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res ? res.data : null;
        if (data) {
          response.success = true;
          response.data = data;
        }
        else {
          response.error = 'Une erreur est survenue lors de l\'export !';
        }
        return response;
      } catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getPdf: async (echantillon, typeDoc, token) => {
    let response = {
      success: false,
      data: [],
      filename: '',
      error: ''
    };
    if (token && echantillon) {
      let headers = {
        'Authorization': 'Bearer ' + token
      };
      let user = store.getters['auth/user'];

      try {
        let res = await axios({
          url: `echantillons/${typeDoc}/${echantillon}`,
          method: 'GET',
          responseType: 'blob',
          headers: headers,
          params: {
            'tiers-code': user.login
          }
        });

        let data = res ? res.data : null;
        let filename = res && res.headers ? res.headers["content-disposition"] : 'echantillon_';
        if (data) {
          response.success = true;
          response.filename = filename;
          response.data = data;
        }
        else {
          response.error = 'Fichier introuvable !';
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getAllPdfs: async (echantillons, token, login) => {
    let response = {
      success: false,
      data: [],
      filename: '',
      error: ''
    };
    if (token && echantillons && login) {
      let headers = {
        'Authorization': 'Bearer ' + token
      };

      try {
        let res = await axios({
          url: `echantillons/all-documents`,
          method: 'POST',
          responseType: 'blob',
          headers: headers,
          params: {
            'tiers-code': login
          },
          data: {
            'echantillons': echantillons
          }
        });

        let data = res ? res.data : null;
        let filename = res && res.headers ? res.headers["content-disposition"] : 'pdfs_';
        let status = res ? res.status : null;
        if (status === 204) {
          response.error = "Fichier trop volumineux";
        }
        else if (data) {
          response.success = true;
          response.filename = filename;
          response.data = data;
        }
        else {
          response.error = 'Fichier introuvable !';
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getAutoCompleteData: async (field, search, token) => {
    let response = {
      success: false,
      data: [],
      warn: false,
      error: ''
    };
    if (token && search && field) {
      let url = field === 'produits' ? `produits?libelle-lk=` : `${field}?ref-lk=`;

      let headers = {
        'Authorization': 'Bearer ' + token
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.get(`${url}${search}`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data && data.length > 0) {
          response.success = true;
          response.data = data;
        }
        else {
          response.warn = true;
          response.error = 'Pas de résultats correspondants.';
        }
        return response;
      } catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  /*************************commandes*****************************/

  getTestAccess: async (token) => {
    let response = {
      success: false,
      isOK: false,
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get('test-access', { 'headers': headers, 'params': { 'tiers-code': user.login } });
        if (res) {
          response.success = true;
          response.isOK = true;
        }
        return response;
      }
      catch (error) {
        response.error = 'pas d\'access aux commandes';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getCommandes: async (token, limit, offset, sort, search) => {
    let response = {
      success: false,
      commandes: [],
      nbCommandes: 0,
      msg: '',
      error: ''
    };
    if (limit && token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let q = search ? `&q=${search}` : '';
        let order = sort ? `&order=${sort}`: '';
        let res = await axios.get(`commandes?limit=${limit}&offset=${offset}`+order+q, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        let meta = res && res.data ? res.data.meta : null;
        if (data) {
          response.success = true;
          response.commandes = data;
          response.nbCommandes = parseInt(meta.max_rows);
          response.msg = `${data.max_rows} résultat(s) trouvé(s).`;
        }
        else {
          response.success = true;
          response.msg = 'Pas de résultats correspondants.';
        }
        return response;
      } catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getAllCommandes: async (token, search) => {
    let response = {
      success: false,
      commandes: [],
      nbCommandes: 0,
      msg: '',
      error: ''
    };
    if (search && token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes?q=all:lk:`+search, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        let meta = res && res.data ? res.data.meta : null;
        if (data) {
          response.success = true;
          response.commandes = data;
          response.nbCommandes = parseInt(meta.max_rows);
          response.msg = `${data.max_rows} résultat(s) trouvé(s).`;
        }
        else {
          response.success = true;
          response.msg = 'Pas de résultats correspondants.';
        }
        return response;
      } catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getContrats: async (token, withPerime) => {
    let response = {
      success: false,
      contrats: [],
      error: ''
    };
    if (token) {
      withPerime = withPerime || false;
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let params = {
          'tiers-code': user.login,
          'with-perime': withPerime ? '1' : undefined
        };
        let res = await axios.get('contrats', { headers, params });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.contrats = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getEtats: async (token) => {
    let response = {
      success: false,
      etats: [],
      error: ''
    };

    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get('etats', { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.etats = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getCommande: async (token, id) => {
    let response = {
      success: false,
      commande: [],
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${id}`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.commande = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getInfos: async (token, id) => {
    let response = {
      success: false,
      infos: {},
      error: ''
    };
    if (token && id) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${id}/infos`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.infos = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getAnalyses: async (token, id) => {
    let response = {
      success: false,
      analyses: [],
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${id}/analyses`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.analyses = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getFamilles: async (token) => {
    let response = {
      success: false,
      familles: [],
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`familles`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.familles = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getSousFamilles: async (token, idFamille) => {
    let response = {
      success: false,
      sousFamilles: [],
      error: ''
    };
    if (token || idFamille) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`sousfamilles/${idFamille}`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.sousFamilles = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getUnites: async (token) => {
    let response = {
      success: false,
      unites: [],
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get('unites', { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.unites = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getLmrs: async (token) => {
    let response = {
      success: false,
      lmrs: [],
      error: ''
    };

    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get('lmr', { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.lmrs = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getEchantillionsCommande: async (token, idc) => {
    let response = {
      success: false,
      echantillons: [],
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${idc}/echantillons`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.echantillons = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getEchantillionCommande: async (token, idc, id) => {
    let response = {
      success: false,
      echantillon: [],
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${idc}/echantillons/${id}`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.echantillon = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getBonCommande: async (token, idc) => {
    let response = {
      success: false,
      bonCommande: "",
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${idc}/bon-commande`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.bonCommande = data.pdf_content;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getEtequetteChronopost: async (token, idc) => {
    let response = {
      success: false,
      etiquette: "",
      error: ''
    };
    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${idc}/etiquette`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.etiquette = data.pdf;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getTrakingChronopost: async (token, idc) => {
    let response = {
      success: false,
      suivi: "",
      error: ''
    };

    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${idc}/tracking`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.suivi = data;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  getResultsId: async (token, idc) => {
    let response = {
      success: false,
      idResults: "",
      error: ''
    };

    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let user = store.getters['auth/user'];
        let res = await axios.get(`commandes/${idc}/resultats`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.idResults = data.id;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  /**
   * Récupére des infos d'environnement depuis le back.
   *
   * @param {string} token
   * @returns Object
   */
  getEnvs: async (token) => {
    let response = {
      success: false,
      lmrs: [],
      error: ''
    };

    if (token) {
      try {
        let headers = {
          'Authorization': 'Bearer ' + token
        };
        let res = await axios.get('envs', { 'headers': headers });
        let data = res && res.data ? res.data.data : null;
        if (data) {
          response.success = true;
          response.cgusUrl = data.cgusUrl;
          response.precapUrl = data.precapUrl;
        }
        return response;
      }
      catch (error) {
        response.error = 'Une erreur est survenue !';
        return response;
      }
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

};
