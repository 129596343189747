<template>
  <v-container fluid grid-list-md>
    <v-card class="mx-auto" max-width="100%" outlined>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <span class="cmd-title">Commande</span>
            <span v-if="commande.numero" class="cmd-num">N° {{ commande.numero }}</span>
            <div class="right-cmd">
              <v-btn v-if="!edition && commande.etat === '6'" large color="info" @click="goToResults()">
                <v-icon left>
                  fas fa-poll
                </v-icon>
                Consulter les résultats
              </v-btn>
              <v-icon
                v-if="commande.etat !== '1'"
                :title="getEtat(commande.etat)"
                class="com-icon"
                color="error"
                size="58"
              >
                fas fa-lock
              </v-icon>
              <v-btn v-if="lastEchVisited && commande.id === lastEchVisited.cid && lastEchVisited.id" class="next-btn" icon @click="gotoNext()">
                <v-icon color="blue" size="48">
                  fas fa-arrow-circle-right
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="commande.etat !== '1' && commande.chronopost" class="row-c">
          <v-col cols="5">
            <v-spacer />
            <img class="img-colis" :src="require('@/assets/colis.png')" alt="colis">
          </v-col>
          <v-col cols="7">
            <div class="id-chp">
              N° {{ commande.chronopost }}
            </div>
            <div class="etat-cmd">
              {{ lastLibelle }}
            </div>
          </v-col>
        </v-row>
        <v-row v-if="commande.etat !== '1' && commande.chronopost" class="row-c">
          <v-col class="etat-items" cols="12">
            <div class="etat-item">
              <v-icon :color="!EC ? 'blue': ''" size="48">
                {{ EC ? 'fas fa-check-circle' : 'fas fa-cube' }}
              </v-icon>
              <div class="etat-ko">
                Colis n'est pas pris en charge
              </div>
            </div>
            <span>
              <div :class="EC ? 'line-dark' : 'line-clair'" />
              <div class="etat-item">
                <v-icon :color="TS ? '': EC ? 'blue': '#ced4da'" size="48">
                  {{ TS ? 'fas fa-check-circle' : EC ? 'fas fa-cube': 'fas fa-circle' }}
                </v-icon>
                <div :class="EC ? 'etat-ok' : 'etat-ko'">
                  Tri effectué dans l'agence de départ
                </div>
              </div>
              <div :class="TS ? 'line-dark' : 'line-clair'" />
              <div class="etat-item">
                <v-icon :color="SD ? '': TS ? 'blue': '#ced4da'" size="48">
                  {{ SD ? 'fas fa-check-circle' : TS ? 'fas fa-cube': 'fas fa-circle' }}
                </v-icon>
                <div :class="TS ? 'etat-ok' : 'etat-ko'">
                  Colis en cours d'acheminement
                </div>
              </div>
              <div :class="SD ? 'line-dark' : 'line-clair'" />
              <div class="etat-item">
                <v-icon :color="TA ? '': SD ? 'blue': '#ced4da'" size="48">
                  {{ TA ? 'fas fa-check-circle' : SD ? 'fas fa-cube': 'fas fa-circle' }}
                </v-icon>
                <div :class="TA ? 'etat-ok' : 'etat-ko'">
                  Tri effectué dans l'agence de distribution
                </div>
              </div>
              <div :class="TA ? 'line-dark' : 'line-clair'" />
              <div class="etat-item">
                <v-icon :color="D ? '': TA ? 'blue': '#ced4da'" size="48">
                  {{ D ? 'fas fa-check-circle' : TA ? 'fas fa-cube': 'fas fa-circle' }}
                </v-icon>
                <div :class="TA ? 'etat-ok' : 'etat-ko'">
                  Colis en cours de livraison
                </div>
              </div>
              <div :class="D ? 'line-dark' : 'line-clair'" />
              <div class="etat-item">
                <v-icon :color="D ? 'blue': '#ced4da'" size="48">
                  {{ D ? 'fas fa-cube' : 'fas fa-circle' }}
                </v-icon>
                <div :class="D ? 'etat-ok' : 'etat-ko'">
                  Livraison effectuée
                </div>
              </div>
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="12" md="4">
            <v-select
              v-if="edition"
              v-model="commande.contrat_code"
              :items="contratsActifs"
              :item-text="item => item.text"
              :item-value="item => item.value"
              :rules="required"
              label="Contrat"
              no-data-text="Contrats non disponibles"
              @change="changeContrat(commande.contrat_code)"
            />
            <span v-else>
              <span class="read-label">Contrat :</span>
              <span>{{ getLibelleContrat(commande.contrat_code, contrats) }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="2" />
          <v-col sm="10" md="2" class="read-created">
            <span class="read-label">Créateur :</span>
            <span>{{ user.name }}</span>
          </v-col>
          <v-col sm="10" md="4">
            <div v-if="commande.etat !== '1'">
              <v-btn
                v-if="commande.id"
                color="error"
                class="btn-gene"
                @click="getBonCommande()"
              >
                <span class="btn-text">
                  Obtenir votre bon de commande
                </span>
              </v-btn>
              <v-btn
                v-if="commande.id && commande.etat === '2'"
                color="chronopost"
                @click="getEtiquetteChronopost()"
              >
                <span class="btn-text">
                  Obtenir votre bon Chronopost
                </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" md="4">
            <v-text-field
              v-if="edition"
              v-model="commande.reference"
              label="Votre référence commande"
              maxlength="30"
            />
            <span v-else>
              <span class="read-label">Votre référence commande :</span>
              <span>{{ commande.reference }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="2" />
          <v-col sm="12" md="4" class="read-created">
            <span class="read-label">Créée le :</span>
            <span>{{ getFormatedDate(commande.date_creation) }}</span>
          </v-col>
          <v-col sm="0" md="2" />
        </v-row>
        <v-row>
          <v-col sm="12" md="4">
            <v-textarea
              v-if="edition"
              v-model="commande.commentaire"
              auto-grow
              label="Contact (Nom et téléphone)"
              rows="1"
              maxlength="100"
            />
            <span v-else>
              <span class="read-label">Contact (Nom et téléphone) :</span>
              <span>{{ commande.commentaire }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="2" />
          <v-col sm="12" md="4" class="read-created">
            <app-date
              v-if="edition"
              v-model="commande.date_prev_reception"
              :initial-date="commande.date_prev_reception"
              label="Date prévisionnelle de reception"
              @dateChange="checkPrevDate"
            />
            <span v-else>
              <span class="read-label">Date prévisionnelle de reception :</span>
              <span>{{ getFormatedDate(commande.date_prev_reception) }}</span>
            </span>
          </v-col>
          <v-col sm="0" md="2" />
        </v-row>
        <v-row v-if="commande.echantillons && commande.echantillons.length > 0">
          <v-col sm="12" md="12">
            <span class="ech-title">Échantillons de la commande :</span>
          </v-col>
        </v-row>
        <v-row v-if="commande.echantillons && commande.echantillons.length > 0">
          <v-col sm="12" md="12">
            <list-echantillon :cid="commande.id" :data="commande.echantillons" :analyses="analyses" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="!edition && commande.etat === '1'" color="info" @click="addEchantillon()">
          <v-icon left>
            fas fa-plus
          </v-icon>
          Saisir un échantillon
        </v-btn>

        <v-spacer />

        <v-btn v-if="commande.etat === '1'" color="error" @click="askDeleteCommande()">
          <v-icon left>
            fas fa-trash-alt
          </v-icon>
          Supprimer
        </v-btn>

        <v-btn v-if="!edition" color="warning" @click="duplicateCommande()">
          <v-icon left>
            fas fa-clone
          </v-icon>
          Dupliquer
        </v-btn>

        <v-btn v-if="edition && !commande.id && commande.etat === '1'" color="info" @click="saveCommande()">
          <v-icon left>
            fas fa-save
          </v-icon>
          Enregistrer
        </v-btn>

        <v-btn v-else-if="edition && commande.id && commande.etat === '1'" color="info" @click="updateCommande()">
          <v-icon left>
            fas fa-save
          </v-icon>
          Enregistrer
        </v-btn>

        <v-btn v-else-if="commande.etat === '1'" color="info" @click="editCommande()">
          <v-icon left>
            fas fa-edit
          </v-icon>
          Modifier
        </v-btn>

        <v-btn v-if="!edition && commande.etat === '1' && commande.echantillons && commande.echantillons.length > 0" color="primary" @click="lockCommande()">
          <v-icon left>
            fas fa-lock
          </v-icon>
          Transmettre à Capinov
        </v-btn>
      </v-card-actions>
    </v-card>
    <app-dialog
      :active="isContratChange || isToDelete"
      :is-to-confirm="true"
      :message="message"
      @confirmDialog="confirmDialog"
    />
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import AppDialog from '@/components/common/AppDialog';
import AppDate from "@/components/common/AppDate";
import ListEchantillon from "@/components/commande/listEchInCommande";
import mixins from '@/mixins/mixins.js';
import searchSrv from "@/services/searchService";
import updateSrv from "@/services/updateService";
import moment from "moment";

export default {
  components: {
    AppDialog,
    AppDate,
    ListEchantillon
  },
  mixins: [mixins],
  data() {
    return {
      allContrats: [],
      isContratChange: false,
      isToDelete: false,
      contrats: [],
      contratsActifs: [],
      analyses: [],
      edition: true,
      isToSave: false,
      oldContratCode: "",
      message: "",
      duplicate: false,
      lastLibelle: "Colis n'est pas pris en charge",
      EC: null,
      TS: null,
      SD: null,
      TA: null,
      D: null,
      commande: {
        id: null,
        numero: "",
        reference: "",
        contrat_code: "",
        commentaire: "",
        //date_envoi: "",
        date_creation: null,
        date_prev_reception: "",
        echantillons: [],
        etat: "1",
        chronopost: "" //XY688201788EE
      },
      required: [v => !!v || "Champ requis"]
    };
  },

  computed: {
    ...mapGetters("auth", ["isLogin", "user"]),
    ...mapGetters("data", ["listAllContrats", "listContratsActifs", "lastEchVisited", "currentCommande", "listAnalyses", "listEtats"])
  },

  watch: {
     'commande.contrat_code'(newVal, oldVal) {
      if(newVal !== oldVal && newVal && oldVal && this.commande.id) {
        this.oldContratCode = oldVal;
      }
    },
    // A l'édition, on ne prend que les contrats actifs.
    edition(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === true) {
          this.contrats = this.contratsActifs;
        } else {
          this.contrats = this.allContrats;
        }
      }
    }
  },

  created() {
    if (!this.commande.date_creation) {
      this.commande.date_creation = moment().format("YYYY-MM-DD");
    }
    if (this.user && !this.commande.commentaire) {
      this.commande.commentaire = `${this.user.name} ${this.user.firstName || ''} ${this.user.phone || ''}`;
    }
    this.getFamilles();
    this.getAllContrats();
    this.getContratsActifs();
    this.getCommande();
  },

  methods: {
    // Mapping des mutations du store.
    ...mapMutations("loading", ["LOADING"]),
    ...mapMutations("data", ["setListAllContrats", "setListContratsActifs", "setListFamille", "setCurrentCommande", "setListAnalyses"]),

    goTo(page) {
      this.$router.push("/" + page + "/");
    },

    gotoNext() {
      if(this.lastEchVisited && this.commande.id === this.lastEchVisited.cid) {
        this.$router.push("/echantillon/" + this.commande.id + "/" + this.lastEchVisited.id);
      }
    },

    editCommande() {
      this.edition = true;
    },

    lockCommande() {
      this.commande.date_envoi = moment().format("YYYY-MM-DD");
      this.validateCommande();
    },

    duplicateCommande() {
      this.edition = true;
      this.commande.id = null;
      this.commande.numero = null;
      this.commande.reference = "";
      this.commande.etat = "1";
      this.commande.date_creation = moment().format("YYYY-MM-DD");
      this.commande.date_envoi = moment().format("YYYY-MM-DD"); //TODO erreur d'enregistrement la date doit etre null
      this.commande.date_prev_reception = null;
      this.duplicate = true;
    },

    addEchantillon() {
      this.$router.push("/echantillon/" + this.commande.id + "/");
    },

    checkPrevDate(date) {
      let creationDate = this.commande.date_creation;
      let prevDate = date;
      if (moment(prevDate, 'YYYY-MM-DD').isBefore(moment(creationDate, 'YYYY-MM-DD'))) {
        this.$toast.warning(
          "La date prévisionnelle de réception doit être superieur à la date de création de la commande."
        );
      } else {
        this.commande.date_prev_reception = date;
      }
    },

    getFormatedDate(date) {
      return moment(date, 'YYYY-MM-DD').format("L");
    },

    resetAnalyses() {
      this.analyses = [];
      this.setListAnalyses(this.analyses);
      for(let i = 0; i < this.commande.echantillons.length; i++) {
        this.commande.echantillons[i].analyses = [];
      }
    },

    confirmDialog(isToConfrim) {
      if(this.isToDelete && isToConfrim) {
        this.deleteCommande();
        this.isToDelete = false;
        return;
      }
      else if(this.isToDelete && !isToConfrim) {
        this.isToDelete = false;
        return;
      }

      if(isToConfrim && this.duplicate && this.commande.echantillons && this.commande.echantillons.length) {
        this.resetAnalyses();
        this.saveCommande();
      }
      if(!isToConfrim) {
        this.commande.contrat_code = this.oldContratCode;
      }
      else if(isToConfrim && !this.duplicate) {
        this.resetAnalyses();
        this.updateCommande();
      }

      this.isContratChange = false;
    },

    changeContrat(contrat) {
      if(this.commande.id) {
        this.message = "La modification de contrat supprime toutes les analyses des échantillons.";
        this.isContratChange = true;
      }
      else if(this.duplicate && this.commande.echantillons && this.commande.echantillons.length) {
        this.message = "La modification de contrat supprime toutes les analyses des échantillons.";
        this.isContratChange = true;
      }
    },

    checkMandatoryFields() {
      if (!this.commande.contrat_code) {
        this.$toast.warning("Le contrat n'est pas renseignée");
        return false;
      }
      if (!this.commande.date_prev_reception) {
        this.$toast.warning("La date prévisionnelle n'est pas renseignée");
        return false;
      }
      if (!this.commande.commentaire) {
        this.$toast.warning("Le contact ( Nom et téléphone) n'est pas renseignée");
        return false;
      }
      return true;
    },

    resetDuplicatedEchantillon(ech, cid) {
      let echantillon = {
        numero: "",
        commande_id: cid,
        reference: "",
        lot: "",
        identification: "",
        famille: ech.famille,
        sous_famille: ech.sous_famille,
        quantite: 0,
        unite: ech.unite,
        commentaire: ech.commentaire ? ech.commentaire : this.user ? this.user.name +" "+this.user.firstName +" "+this.user.phone : "",
        date_creation: "",
        date_fabrication: "",
        date_prelevement: "",
        date_dlc: "",
        date_ddm: "",
        lmr: ech.lmr,
        analyses: ech.analyses,
        info_1: "",
        info_2: "",
        info_3: "",
        info_4: ""
      };
      return echantillon;
    },

    getLastLibelleChronopost() {
      if(this.suiviChronopost && this.suiviChronopost.length > 0) {
        this.lastLibelle = this.suiviChronopost[this.suiviChronopost.length - 1].libelle;
      }
    },

    editTraking(suivi) {
      if(suivi) {
        for(let event of suivi) {
          if(event.code === "DC") {
            this.DC = event;
          }
          else if(event.code === "EC") {
            this.EC = event;
          }
          else if(event.code === "TS") {
            this.TS = event;
          }
          else if(event.code === "SD") {
            this.SD = event;
          }
          else if(event.code === "TA") {
            this.TA = event;
          }
          else if(event.code === "D") {
            this.D = event;
          }
          this.lastLibelle = event.libelle;
        }
      }
    },

    askDeleteCommande() {
      this.message = "Etes-vous sûr de vouloir supprimer la commande ?";
      this.isToDelete = true;
    },

    getEtat(code) {
      let status = "Commande ";
      for(let etat of this.listEtats) {
        if(etat.value === code) {
          status += etat.text.toLowerCase();
        }
      }
      return status;
    },

    async goToResults() {
      this.LOADING(true);
      let response = await searchSrv.getResultsId(this.user.token, this.commande.id);
      this.LOADING(false);
      if (response.success) {
        this.$router.push("/resultats/" + response.idResults);
      } else {
        this.$toast.error(response.error);
      }
    },

    async getFamilles() {
      if (this.listFamille && this.listFamille.length > 0) {
        this.familles = this.listFamille;
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getFamilles(this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.familles = this.getMappedItems(response.familles);
        this.setListFamille(this.familles);
        this.getEchantillons();
      } else {
        this.$toast.error(response.error);
      }
    },

    async getAllContrats() {
      if (this.listAllContrats && this.listAllContrats.length > 0) {
        this.allContrats = this.listAllContrats;
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getContrats(this.user.token, true);
      this.LOADING(false);

      if (response.success) {
        this.contrats = this.getMappedContrats(response.contrats);
        this.setListAllContrats(this.contrats);
      } else {
        this.$toast.error(response.error);
      }
    },

    async getContratsActifs() {
      if (this.listContratsActifs && this.listContratsActifs.length > 0) {
        this.contratsActifs = this.listContratsActifs;
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getContrats(this.user.token);
      this.LOADING(false);

      if (response.success) {
        this.contratsActifs = this.getMappedContrats(response.contrats);
        this.setListContratsActifs(this.contratsActifs);
      } else {
        this.$toast.error(response.error);
      }
    },

    async getCommande() {
      if (!this.$route.params.id) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getCommande(this.user.token, this.$route.params.id);
      this.LOADING(false);

      if (response.success) {
        this.commande = response.commande;
        this.getAnalyses();
        this.setCurrentCommande(this.commande);
        if (this.user && !this.commande.commentaire) {
          this.commande.commentaire = `${this.user.name} ${this.user.firstName || ''} ${this.user.phone || ''}`;
        }
        this.edition = false;
        if(this.commande.chronopost) {
          this.getTrakingChronopost();
        }
      } else {
        this.$toast.error(response.error);
      }
    },

    async getEchantillons() {
      if (!this.$route.params.id) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getEchantillionsCommande(this.user.token, this.$route.params.id);
      this.LOADING(false);

      if (response.success) {
        this.commande.echantillons = response.echantillons;
        this.edition = false;
      } else {
        this.$toast.error(response.error);
      }
    },

    async getAnalyses() {
      if(!this.commande.id) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getAnalyses(this.user.token, this.commande.id);
      this.LOADING(false);

      if (response.success) {
        this.analyses = this.getFormatedAnalyses(response.analyses);
        this.setListAnalyses(this.analyses);
      } else {
        this.$toast.error(response.error);
      }
    },

    async saveCommande() {
      if(!this.checkMandatoryFields()) {
        return;
      }
      if(this.commande.hasOwnProperty('date_envoi')) {
        this.commande.date_envoi = moment().format("YYYY-MM-DD");
      }
      this.LOADING(true);
      let response = await updateSrv.saveCommande(this.user.token, this.commande);
      this.LOADING(false);

      if (response.success) {
        let echantillons = this.commande.echantillons;
        if(this.duplicate && echantillons && echantillons.length > 0) {
          for(let ech of echantillons) {
            let echantillon = this.resetDuplicatedEchantillon(ech);
            await this.saveEchantillon(echantillon, response.commande.id);
          }
          this.duplicate = false;
        }
        this.edition = false;
        this.commande = response.commande;
        this.getAnalyses();
        this.setCurrentCommande(this.commande);
        this.commande.echantillons = echantillons;
        this.$router.push("/commande/" + this.commande.id);
      } else {
        this.$toast.error(response.error);
      }
    },

    async updateCommande() {
      if(!this.checkMandatoryFields()) {
        return;
      }

      if(this.commande.date_envoi === null) {
        delete this.commande.date_envoi;
      }

      this.LOADING(true);
      let response = await updateSrv.updateCommande(this.user.token, this.commande);
      this.LOADING(false);

      if (response.success) {
        this.edition = false;
        let echantillons = this.commande.echantillons;
        this.commande = response.commande;
        this.setCurrentCommande(this.commande);
        this.getAnalyses();
        this.commande.echantillons = echantillons;
      } else {
        this.$toast.error(response.error);
      }
    },

    async deleteCommande() {
      if(!this.commande.id) {
        this.$router.push("/commandes/");
        return;
      }

      this.LOADING(true);
      let response = await updateSrv.deleteCommande(this.user.token, this.commande);
      this.LOADING(false);

      if (response.success) {
        this.$router.push("/commandes/");
      } else {
        this.$toast.error(response.error);
      }
    },

    async validateCommande() {
      if(!this.commande.id) {
        this.$toast.error("Vous ne pouvez pas valider cette commande");
        return;
      }
      let creationDate = moment().format("YYYY-MM-DD");
      if (moment(this.commande.date_prev_reception, "YYYY-MM-DD").isBefore(moment(creationDate, "YYYY-MM-DD"))) {
        this.$toast.warning(
          "La date prévisionnelle de réception doit être superieur à la date d'aujourd'hui."
        );
        return;
      }

      this.LOADING(true);
      let echantillons = this.commande.echantillons;
      delete this.commande.echantillons;
      let response = await updateSrv.validateCommande(this.user.token, this.commande);
      this.LOADING(false);

      if (response.success) {
        this.commande = response.commande;
        this.setCurrentCommande(this.commande);
        this.commande.echantillons = echantillons;
      }
      else {
        if(response.wrg) {
          this.$toast.warning(response.error);
        }
        else {
          this.$toast.error(response.error);
        }
      }
    },

    async saveEchantillon(echantillon, cid) {
      this.LOADING(true);
      let response = await updateSrv.saveEchantillon(this.user.token, cid, echantillon);
      this.LOADING(false);

      if (!response.success) {
        this.$toast.error(response.error);
      }
    },

    async getBonCommande() {
      if(!this.commande.id) {
        this.$toast.error("Vous ne pouvez pas téléchager le bon commande");
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getBonCommande(this.user.token, this.commande.id);
      this.LOADING(false);

      if (response.success) {
        this.toPdf(response.bonCommande, "bon-de-commande-"+ this.commande.numero);
      }
      else {
        if(response.wrg) {
          this.$toast.warning(response.error);
        }
        else {
          this.$toast.error(response.error);
        }
      }
    },

    async getEtiquetteChronopost() {
      if(!this.commande.id) {
        this.$toast.error("Vous ne pouvez pas téléchager l'étiquette");
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getEtequetteChronopost(this.user.token, this.commande.id);
      this.LOADING(false);

      if (response.success) {
        this.toPdf(response.etiquette, 'chronopost-'+this.commande.numero);
        let echantillons = this.commande.echantillons;
        await this.getCommande();
        this.commande.echantillons = echantillons;
      }
      else {
        if(response.wrg) {
          this.$toast.warning(response.error);
        }
        else {
          this.$toast.error(response.error);
        }
      }
    },

    async getTrakingChronopost() {
      if(!this.commande.id || !this.commande.chronopost) {
        return;
      }

      this.LOADING(true);
      let response = await searchSrv.getTrakingChronopost(this.user.token, this.commande.id);
      this.LOADING(false);

      if (response.success) {
        this.editTraking(response.suivi);
      }
      else {
        if(response.wrg) {
          this.$toast.warning(response.error);
        }
        else {
          this.$toast.error(response.error);
        }
      }
    }
  }
};
</script>

<style>
.row-c {
  width: 100%;
}
.right-cmd {
  float: right;
}
.cmd-title {
  color: #7ab737;
  font-size: 1.5em;
  margin-right: 10px;
}
.cmd-num {
  font-size: 1em;
  padding-top: 10px;
}
.read-created {
  padding-top: 25px;
}
.codebare svg {
  vertical-align: middle;
  max-width: 180px;
  max-height: 80px;
}
.com-icon {
  padding: 0px 10px 0px 10px;
  margin-bottom: 10px;
}
.next-btn {
  margin: 0px 10px 10px 10px;
}
.ech-title {
  font-weight: 700;
}
.print-btn {
  float: right;
}
.read-label {
  font-weight: 500;
  padding-right: 10px;
}
.id-chp {
  font-size: 16px;
  font-weight: 500;
}
.etat-cmd {
  font-size: 14px;
}
.img-colis {
  width: 100px;
  float: right;
}
.etat-items {
  text-align: center;
}
.etat-item {
  width: 125px;
  vertical-align: text-top;
  word-break: keep-all;
  text-align: center;
  display: inline-block;
}
.line-dark {
  width: 200px;
  height: 10px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.54);
  margin-left: -35px;
  margin-right: -35px;
  position: relative;
  top: 6px;
}
.line-clair {
  width: 200px;
  height: 10px;
  display: inline-block;
  background-color: #16293b;
  margin-left: -35px;
  margin-right: -35px;
  position: relative;
  top: 6px;
}
.etat-ok {
  font-size: 14px;
}
.etat-ko {
  font-size: 14px;
  color: #a2a4a7;
}
.btn-text{
   font-size: 9px;
}
.btn-gene {
  margin-right: 5px;
}
</style>
