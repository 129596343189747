<template>
  <v-container>
    <v-row>
      <v-col cols="4" offset="4">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Oops</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="display-1 ma-7">
            404. Page introuvable.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>

<style>

</style>
