export default {
  // Cloisonne le module qui devient autosuffisant et réutilisable.
  namespaced: true,
  state: {
    loading: false
  },

  getters: {
    loading: state => state.loading
  },

  // Setters.
  mutations: {
    /**
     * Mise à jour de l'Etat du loader fullscreen
     * @param none
     */
    LOADING: (state, value) => {
      state.loading = value;
    }
  },

  /**
   * Toute action asynchrone doit être dans actions
   */
  actions: {

  }
};
