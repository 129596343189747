import store from '@/store';

export default {

  /********************** Commande **********************/
  saveCommande: async (token, commande) => {
    let response = {
      success: false,
      error: '',
      commande: ''
    };

    if (token && commande) {
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/raw'
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.post('commandes', commande, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        if (res && res.data && res.data.data) {
          response.success = true;
          response.commande = res.data.data;
        }
        else {
          response.error = 'Une erreur est survenue lors de la sauvgarde';
        }
      } catch (error) {
        response.error = 'Une erreur est survenue';
      }
      return response;
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  updateCommande: async (token, commande) => {
    let response = {
      success: false,
      error: '',
      commande: ''
    };

    if (token && commande) {
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/raw'
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.put(`commandes/${commande.id}`, commande, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        if (res && res.data && res.data.data) {
          response.success = true;
          response.commande = res.data.data;
        }
        else {
          response.error = 'Une erreur est survenue lors de la mise à jour';
        }
      } catch (error) {
        response.error = 'Une erreur est survenue';
      }
      return response;
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  deleteCommande: async (token, commande) => {
    let response = {
      success: false,
      error: '',
      commande: ''
    };

    if (token && commande) {
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/raw'
      };
      try {
        let user = store.getters['auth/user'];
        await axios.delete(`commandes/${commande.id}`, {'headers': headers, 'params': { 'tiers-code': user.login }}, commande);
        response.success = true;
      } catch (error) {
        response.error = 'Une erreur est survenue';
      }
      return response;
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  validateCommande: async (token, commande) => {
    let response = {
      success: false,
      wrg: false,
      error: '',
      commande: ''
    };

    if (token && commande) {
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/raw'
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.put(`commandes/${commande.id}/valider`, commande, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        if (res && res.data && res.data.data) {
          response.success = true;
          response.commande = res.data.data;
        }
        else {
          response.error = 'Une erreur est survenue lors de la validation';
        }
      } catch (error) {
        if(error.response && error.response.data && error.response.data.meta && error.response.data.meta.error_code === 412) {
          response.error = error.response.data.data.message;
          response.wrg = true;
        }
        else {
          response.error = 'Une erreur est survenue';
        }
      }
      return response;
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  /************** Echantillon d'une commande ***************/

  saveEchantillon: async (token, cid, echantillon) => {
    let response = {
      success: false,
      error: '',
      echantillon: ''
    };

    if (token && echantillon && cid) {
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/raw'
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.post(`commandes/${cid}/echantillons`, echantillon, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        if (res && res.data && res.data.data) {
          response.success = true;
          response.echantillon = res.data.data;
        }
        else {
          response.error = 'Une erreur est survenue lors de la sauvgarde';
        }
      } catch (error) {
        response.error = 'Une erreur est survenue';
      }
      return response;
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  updateEchantillon: async (token, cid, echantillon) => {
    let response = {
      success: false,
      error: '',
      echantillon: ''
    };

    if (token && echantillon && cid) {
      let headers = {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/raw'
      };
      try {
        let user = store.getters['auth/user'];
        let res = await axios.put(`commandes/${cid}/echantillons/${echantillon.numero}`, echantillon, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        if (res && res.data && res.data.data) {
          response.success = true;
          response.echantillon = res.data.data;
        }
        else {
          response.error = 'Une erreur est survenue lors de la mise à jour';
        }
      } catch (error) {
        response.error = 'Une erreur est survenue';
      }
      return response;
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

  deleteEchantillon: async (token, echantillon) => {
    let response = {
      success: false,
      error: ''
    };

    if (token && echantillon) {
      let headers = {
        'Authorization': 'Bearer ' + token
      };
      try {
        let user = store.getters['auth/user'];
        await axios.delete(`commandes/${echantillon.commande_id}/echantillons/${echantillon.numero}`, { 'headers': headers, 'params': { 'tiers-code': user.login } });
        response.success = true;
      } catch (error) {
        response.error = 'Une erreur est survenue';
      }
      return response;
    }
    else {
      response.error = 'Il manque un paramètre';
    }
    return response;
  },

};
